import React, {useCallback, useEffect, useState} from 'react'
import {ModalContext} from '@context/context'

const ModalLayout = ({children}) => {
    const [signInActive, setSignInActive] = useState(false)
    const [signUpActive, setSignUpActive] = useState(false)
    const [verificationWelcomeModalActive, setVerificationWelcomeModalActive] = useState(false)
    const [verificationLoginModalActive, setVerificationLoginModalActive] = useState(false)
    const [verificationResetModalActive, setVerificationResetModalActive] = useState(false)
    const removeModalActivity = useCallback(() => {
        setSignInActive(false)
        setSignUpActive(false)
        setVerificationWelcomeModalActive(false)
        setVerificationLoginModalActive(false)
        setVerificationResetModalActive(false)
    }, [])
    useEffect(() => {
        removeModalActivity()
        return () => {
            removeModalActivity()
        }
    }, [removeModalActivity])
    return (
        <ModalContext.Provider value={{
            signInProps: {signInActive, setSignInActive},
            signUpProps: {signUpActive, setSignUpActive},
            verification: {
                welcome: {verificationWelcomeModalActive, setVerificationWelcomeModalActive},
                login: {verificationLoginModalActive, setVerificationLoginModalActive},
                reset: {verificationResetModalActive, setVerificationResetModalActive}
            }
        }}>
            {children}
        </ModalContext.Provider>
    )
}

export default ModalLayout