import {useCallback, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {mainSlice} from '@store/reducers/mainSlice'

const useWindowSize = () => {
    const {setCurrentSize} = mainSlice.actions
    const dispatch = useDispatch()
    const sizeHandler = useCallback(() => {
        dispatch(setCurrentSize(Number(window.screen.availWidth)))
    }, [dispatch, setCurrentSize])
    useEffect(() => {
        sizeHandler()
    }, [sizeHandler])
    useEffect(() => {
        window.addEventListener('resize', sizeHandler, true)
        return () => {
            window.removeEventListener('resize', sizeHandler)
        }
    }, [sizeHandler])
}

export default useWindowSize