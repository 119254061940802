import {createSlice} from '@reduxjs/toolkit'
import {getUserData, refreshSession, signIn} from '@store/actions/authActions'
import Cookie from 'js-cookie'

const initialState = {
    user: {},
    authorized: Boolean(localStorage?.authorized),
    token: Cookie.get('token'),
    error: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            localStorage.clear()
            sessionStorage.clear()
            Cookie.remove('token')
            state.user = {}
            state.token = null
            state.authorized = false
            state.error = null
        },
        setToken(state, action) {
            state.token = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        }
    },
    extraReducers: {
        [signIn.fulfilled]: (state, action) => {
            const {token, userAud} = action.payload
            Cookie.set('token', token)
            localStorage.authorized = 'true'
            sessionStorage.authorized = 'true'
            localStorage.userAud = userAud
            state.token = token
            state.authorized = true
            state.error = null
        },
        [signIn.rejected]: (state, action) => {
            state.error = action.payload
            state.authorized = false
            state.token = null
        },
        [refreshSession.fulfilled]: (state, action) => {
            Cookie.set('token', action.payload)
            state.token = action.payload
        },
        [getUserData.fulfilled]: (state, action) => {
            state.user = action.payload
        }
    }
})

export default authSlice.reducer

