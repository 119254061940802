import React, {useContext} from 'react'
import SignInInputForm from '@components/Modals/SignIn/SignInInputForm'
import {ModalContext} from '@context/context'
import icon from '@img/RealizeMD white logo.svg'

const SignInIntro = () => {
    const {signInProps: {setSignInActive}, signUpProps: {setSignUpActive}} = useContext(ModalContext)
    const closeModalHandler = () => {
        setSignInActive(false)
        setSignUpActive(true)
    }
    return (
        <div className={`sign-in__intro`}>
            <img src={icon} alt={''} className={`sign-up__logo`}/>
            <h2 className={`sign-in__title input`}>Log In</h2>
            <SignInInputForm/>
            <p className={`sign-in__footer`}>
                Don’t have an account? <button onClick={closeModalHandler}>Sign Up</button>
            </p>
        </div>
    )
}

export default SignInIntro