import React, {useContext} from 'react'
import {ModalContext} from '@context/context'

const Navbar = () => {
    const {signInProps: {setSignInActive}, signUpProps: {setSignUpActive}} = useContext(ModalContext)
    const openSignInHandler = () => {
        setSignInActive(true)
    }
    const openSignUpHandler = () => {
        setSignUpActive(true)
    }
    return (
        <nav className={`nav`}>
            <div className={`nav__intro`}>
                <button
                    onClick={openSignUpHandler}
                    className={`nav__button subscribe`}
                >
                    New Clinic Sign Up
                </button>
                <button
                    onClick={openSignInHandler}
                    className={`nav__button login`}
                >
                    Login
                </button>
            </div>
        </nav>
    )
}

export default Navbar