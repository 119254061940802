import React from "react";
import { NavLink } from "react-router-dom";

const MobileAsidePointItem = ({ point, closePanel, active }) => {
  return (
    <NavLink
      to={point?.path}
      onClick={closePanel}
      className={({ isActive }) =>
        `aside-mobile__point-item${isActive ? " selected" : ""}${
          point?.disabled ? " disabled" : ""
        }`
      }
    >
      <div className={`aside-mobile__point-icon ${active && "active"}`}>
        {point?.icon}
      </div>
      <p className={`aside-mobile__point-title ${active && "active"}`}>
        {point?.title}
      </p>
    </NavLink>
  );
};

export default MobileAsidePointItem;
