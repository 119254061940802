import React from 'react'
import {styled} from '@mui/material'
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip'

const RMDAsideTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}}>
        {props.children}
    </Tooltip>
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: 'rgba(59, 59, 67, 1)'
    },
    [`& .${tooltipClasses.tooltip}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        color: '#DDDDDD',
        backgroundColor: 'rgba(59, 59, 67, 1)',
        textTransform: 'capitalize',
        textAlign: 'center',
        margin: 0,
        padding: '11px 12px',
        top: -3,
        left: 14,
        span: {
            textTransform: 'unset'
        }
    }
}))

export default RMDAsideTooltip