import React from 'react'
import {NavLink} from 'react-router-dom'
import RMDAsideTooltip from '@components/Custom/RMDTooltip/RMDAsideTooltip'

const AsidePointItem = ({point}) => {
    return (
        <NavLink
            to={point?.path}
            className={({isActive}) => `aside__point-item${isActive ? ' selected' : ''}${point?.disabled ? ' disabled' : ''}`}
        >
            <RMDAsideTooltip
                title={point?.title}
                placement={'right'}
                arrow={true}
            >
                <span>{point?.icon}</span>
            </RMDAsideTooltip>
        </NavLink>
    )
}

export default AsidePointItem