import {createAsyncThunk} from '@reduxjs/toolkit'
import UserPool from '@root/UserPool.js'
import {AuthenticationDetails, CognitoRefreshToken, CognitoUser, CognitoUserAttribute} from 'amazon-cognito-identity-js'
import * as _ from 'lodash'

export const signIn = createAsyncThunk(
    'auth/sign-in',
    async (form, thunkAPI) => {
        try {
            if (!form || _.isEmpty(form)) {
                return
            }
            const {email, password} = form
            const user = new CognitoUser({
                Username: email.toLowerCase(),
                Pool: UserPool
            })
            const authDetails = new AuthenticationDetails({
                Username: email,
                Password: password
            })
            return new Promise((resolve, reject) => {
                user.authenticateUser(authDetails, {
                    onSuccess: (data) => {
                        const userAud = data?.getIdToken()?.payload?.aud
                        resolve({
                            token: data.getAccessToken().getJwtToken(),
                            userAud
                        })
                    },
                    onFailure: (err) => {
                        const message = err.message.replaceAll('.', '')
                        reject(thunkAPI.rejectWithValue(message))
                    }
                })
            })
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)
export const signUp = createAsyncThunk(
    'auth/sign-up',
    async (form, thunkAPI) => {
        try {
            const {username, password, agree, ...others} = form
            const customAttributes = Object.entries(others).map(entry => {
                const [Name, Value] = entry
                if (['email'].includes(Name)) {
                    return new CognitoUserAttribute({
                        Name,
                        Value
                    })
                }
                return new CognitoUserAttribute({
                    Name: `custom:${Name}`,
                    Value
                })
            })
            return await new Promise((resolve, reject) => {
                UserPool.signUp(username.toLowerCase(), 'Default-password123', customAttributes, null, (err) => {
                    if (err) {
                        reject(new Error(err.message))
                    }
                    resolve('sign up!')
                })
            })
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const resendCode = createAsyncThunk(
    'auth/resend_code',
    async (username, thunkAPI) => {
        try {
            const user = new CognitoUser({
                Username: username.toLowerCase(),
                Pool: UserPool
            })
            return await new Promise(((resolve, reject) => {
                user.resendConfirmationCode((err) => {
                    if (err) {
                        reject(new Error(err.message))
                    }
                    resolve('code was resent')
                })
            }))
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const confirmRegistration = createAsyncThunk(
    'auth/confirm_registration',
    async (form, thunkAPI) => {
        try {
            const {username, code} = form
            const user = new CognitoUser({
                Username: username,
                Pool: UserPool
            })
            return new Promise(((resolve, reject) => {
                user.confirmRegistration(code, false, (err, data) => {
                    if (err) {
                        reject(new Error(err.message))
                    }
                    resolve(data)
                })
            }))
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const changeUserPassword = createAsyncThunk(
    'auth/change_password',
    async (form, thunkAPI) => {
        try {
            if (!form || _.isEmpty(form)) {
                return
            }
            const {username, password} = form
            const user = new CognitoUser({
                Username: username,
                Pool: UserPool
            })
            const authDetails = new AuthenticationDetails({
                Username: username,
                Password: 'Default-password123'
            })
            return new Promise(((resolve, reject) => {
                user.authenticateUser(authDetails, {
                    onSuccess: () => {
                        user.changePassword('Default-password123', password, (err) => {
                            if (err) {
                                reject(new Error(err.message))
                            }
                            resolve('changed password!')
                        })
                    },
                    onFailure: (err) => {
                        reject(new Error(err.message))
                    }
                })
            }))
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const forgotPassword = createAsyncThunk(
    'auth/forgot_password',
    async (username, thunkAPI) => {
        try {
            const user = new CognitoUser({
                Username: username,
                Pool: UserPool
            })
            return await new Promise(((resolve, reject) => {
                user.forgotPassword({
                    onSuccess: () => {
                        resolve('sent code')
                    },
                    onFailure: (err) => {
                        reject(new Error(err.message))
                    }
                })
            }))
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const confirmPassword = createAsyncThunk(
    'auth/confirm_password',
    async ({username, code, password}, thunkAPI) => {
        try {
            const user = new CognitoUser({
                Username: username,
                Pool: UserPool
            })
            return await new Promise(((resolve, reject) => {
                user.confirmPassword(code, password, {
                    onSuccess: () => {
                        resolve('changed password!')
                    },
                    onFailure: (err) => {
                        reject(new Error(err.message))
                    }
                })
            }))
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const refreshSession = createAsyncThunk(
    'auth/refresh_session',
    async (_, thunkAPI) => {
        try {
            const username = localStorage.getItem('CognitoIdentityServiceProvider.e73g2rs92hbqp747qmb82rn93.LastAuthUser')
            const user = new CognitoUser({
                Username: username,
                Pool: UserPool
            })
            return await new Promise(((resolve, reject) => {
                const token = new CognitoRefreshToken({
                    RefreshToken: localStorage.getItem(`CognitoIdentityServiceProvider.e73g2rs92hbqp747qmb82rn93.${username}.refreshToken`)
                })
                user.refreshSession(token, (err, data) => {
                    if (err) {
                        reject(new Error(err.message))
                    }
                    const newAccessToken = data?.getAccessToken()?.getJwtToken()
                    if (!newAccessToken) {
                        reject('Have no access token')
                    }
                    resolve(newAccessToken)
                })
            }))
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const getUserData = createAsyncThunk(
    'auth/get_user_data',
    async (username, thunkAPI) => {
        try {
            const user = new CognitoUser({
                Username: username,
                Pool: UserPool
            })
            return await new Promise(((resolve, reject) => {
                user.getSession((err, data) => {
                    if (err) {
                        reject(new Error(err.message))
                    }
                    resolve(data?.getIdToken()?.payload)
                })
            }))
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)