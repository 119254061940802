export const API_URL = process.env.REACT_APP_BACKEND_URL || 'https://show-api.realizemd.com'

// console.log(API_URL)

export const MIN_RANDOM_VALUE = 1, MAX_RANDOM_VALUE = 4

export const
    FULLSCREEN_WIDTH = 1920,
    DESKTOP_WIDTH = 1440,
    TABLET_WIDTH = 1024,
    MOBILE_WIDTH = 699,
    SMALL_MOBILE_WIDTH= 375,
    DEFAULT_IMAGE_SIZE = 1024

export const NOT_FORBIDDEN_FILE_TYPES = ['png', 'jpeg', 'jpg']

export const errorMessages = {
    fileType: 'Not available type of file'
}
