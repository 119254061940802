import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {MainContext} from '@context/context'
import {useSelector} from 'react-redux'
import {MOBILE_WIDTH} from '@root/config'

const MainLayout = ({children}) => {
    const {currentSize} = useSelector(state => state.mainReducer)
    const [progressValue, setProgressValue] = useState(0)
    const [progressId, setProgressId] = useState(null)
    const [progressStatusIndex, setProgressStatusIndex] = useState(0)
    const progressStatuses = useMemo(() => ['Preserving clinical relevant area & related landmarks', 'Matching expressions', 'Verifying anonymity'], [])
    const mobileCondition = useMemo(() => currentSize < MOBILE_WIDTH + 1, [currentSize])
    const getCurrentProgressStatus = useCallback(() => progressStatuses[progressStatusIndex], [progressStatusIndex, progressStatuses])
    useEffect(() => {
        if (progressValue > 95) {
            clearInterval(progressId)
        }
    }, [progressId, progressValue])
    useEffect(() => {
        if (progressValue > 30) {
            setProgressStatusIndex(2)
            return
        }
        setProgressStatusIndex(Math.trunc(progressValue / 15))
    }, [progressValue])
    return (
        <MainContext.Provider value={{
            progress: {
                value: {progressValue, setProgressValue},
                id: {progressId, setProgressId},
                info: {getCurrentProgressStatus}
            },
            mobile: {mobileCondition}
        }}>
            {children}
        </MainContext.Provider>
    )
}

export default MainLayout