import React, {useEffect, useMemo, useState} from 'react'
import SignInCreateForm from '@components/Modals/SignIn/SignInCreateForm'
import ReactCodeInput from 'react-code-input'
import icon from '@img/RealizeMD white logo.svg'

const SignInCreateStep = () => {
    const [code, setCode] = useState('')
    const [valid, setValid] = useState(false)
    const [error, setError] = useState(null)
    const props = useMemo(() => ({
        inputStyle: {
            border: `1.5px solid ${valid && !error ? '#DFB729' : 'transparent'}`
        },
        inputStyleInvalid: {
            border: `1.5px solid ${error && !valid ? '#E53737' : 'transparent'}`
        }
    }), [valid, error])
    const changeCode = (value) => {
        setError(false)
        if (!Number(value)) {
            setValid(false)
            setError('Invalid verification code')
        }
        setCode(value)
    }
    useEffect(() => {
        if (!code.length) {
            setError(null)
        }
        setValid(code.length === 6 && !error)
    }, [code, error])
    useEffect(() => {
        if (valid) {
            setError(null)
        }
    }, [valid])
    return (
        <div className={`sign-in__intro`}>
            <img src={icon} alt={''} className={`sign-up__logo`}/>
            <h2 className={`sign-in__title create`}>Create New Password</h2>
            <p className={`sign-in__subtitle create first`}>
                Enter the verification code you received by email to reset
                your password
            </p>
            <div className={`sign-in__verification`}>
                <ReactCodeInput
                    value={code}
                    onChange={changeCode}
                    name={'verification'}
                    inputMode={'numeric'}
                    fields={6}
                    autoFocus={true}
                    isValid={valid}
                    className={`sign-in__code`}
                    {...props}
                />
            </div>
            <p className={`sign-in__error verification${error ? ' active' : ''}`}>{error}</p>
            <p className={`sign-in__subtitle create`}>
                Choose a new password
            </p>
            <p className={`sign-in__description create`}>
                Password must be at least 8 characters long and include
                an uppercase character and a number or symbol.
            </p>
            <SignInCreateForm
                code={code}
            />
        </div>
    )
}

export default SignInCreateStep