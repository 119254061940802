import React, {useContext} from 'react'
import {Box, CircularProgress, ThemeProvider, Typography} from '@mui/material'
import {progressTheme} from '@themes/progressTheme'
import {useSelector} from 'react-redux'
import {MainContext} from '@context/context'

const Progress = () => {
    const {progress: {value: {progressValue}, info: {getCurrentProgressStatus}}} = useContext(MainContext)
    const {progressing} = useSelector(state => state.mainReducer)
    return (
        <ThemeProvider theme={progressTheme}>
            <Box className={`progress modal${progressing ? ' active' : ''}`}>
                <Box className={`progress__body`}>
                    <CircularProgress variant="determinate" value={progressValue}/>
                    <Box className={`progress__typography`}>
                        <Typography variant={'caption'} component={'div'} className={`value`}>
                            {`${Math.round(progressValue)}%`}
                        </Typography>
                    </Box>
                </Box>
                {progressing && (
                    <Typography variant={'caption'} component={'div'} className={`status`}>
                        {getCurrentProgressStatus()}
                    </Typography>
                )}
            </Box>
        </ThemeProvider>
    )
}

export default Progress