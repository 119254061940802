import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useRoutes from '@routes/useRoutes'
import Header from '@components/Header/Header'
import Aside from '@components/Aside/Aside'
import ContextLayout from '@layouts/ContextLayout'
import PrivateBetaButton from '@components/Buttons/PrivateBeta/PrivateBetaButton'
import AuthorizedLayout from '@layouts/AuthorizedLayout'
import UnauthorizedLayout from '@layouts/UnauthorizedLayout'
import Loader from '@components/Loader/Loader'
import {getUserData, refreshSession} from '@store/actions/authActions'
import Progress from '@components/Progress/Progress'
import {authSlice} from '@store/reducers/authSlice'
// import SignUpModal from '@components/Modals/SignUp/SignUpModal'
import useWindowSize from '@hooks/useWindowSize'
import {useLocation} from 'react-router-dom'
import Footer from '@components/Footer/Footer'
import {MOBILE_WIDTH, TABLET_WIDTH} from '@root/config'
import FlipMessage from './components/FlipMessage/FlipMessage'

const ParentLayout = ({authorized, children}) => {
    return authorized ?
        <AuthorizedLayout>{children}</AuthorizedLayout> :
        <UnauthorizedLayout>{children}</UnauthorizedLayout>
}

const App = () => {
    const {authorized} = useSelector(state => state.authReducer)
    const {currentSize} = useSelector(state => state.mainReducer)
    const {anonymizationStep} = React.lazy(useSelector(state => state.anonymizationReducer))
    const route = useRoutes(authorized)
    const dispatch = useDispatch()
    const location = useLocation()
    const {logout} = authSlice.actions
    const mobileCondition = useMemo(() => currentSize < MOBILE_WIDTH + 1, [currentSize])
    const pagesWithoutButton = useMemo(() => ['private_beta', 'terms_of_use', 'privacy_policy'], [])
    const purePath = useMemo(() => location?.pathname?.split('/')[1], [location?.pathname])
    const shortedMainRoutes = useMemo(() => ['portfolio'], [])
    const shortedMainCondition = useMemo(() =>
            shortedMainRoutes.includes(purePath) ||
            (anonymizationStep === 3 && purePath === 'anonymization' && currentSize > TABLET_WIDTH + 1)
        , [anonymizationStep, currentSize, purePath, shortedMainRoutes])
    const displayJoinButton = useMemo(() =>
            !mobileCondition && !pagesWithoutButton?.includes(location?.pathname?.split('/')[1]),
        [location?.pathname, mobileCondition, pagesWithoutButton]
    )
    const wrapperRef = useRef(null)

    // for screen orientaion page ... add additional check for mobile laptop? smth wrong...
    // screen.orientation.lock !!! || window.ScreenOrientation
    // not in use
    // const blockOrientation = useCallback(() => {
    //     const lockOrientation = screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation||window.ScreenOrientation
    //     lockOrientation
    //         .lock('landscape')
    //         .then(
    //             success => {
    //                 console.log(success)
    //             }
    //         )
    // }, [])

    // authorization check cognito 
    const checkAuthUser = useCallback(() => {
        // session check 
        if(!sessionStorage.authorized){ 
            dispatch(logout());
             return
            }
        const userAud = localStorage?.userAud
        const username = localStorage[`CognitoIdentityServiceProvider.${userAud}.LastAuthUser`]
        // authorization check for cognito in local storage 
        if (!username) {
            dispatch(logout())
            return
        }
        dispatch(getUserData(username))
    }, [dispatch, logout])
    useWindowSize()

    useEffect(() => {
        if(sessionStorage.authorized){ 
            dispatch(refreshSession())
            .then(res => {
                if (res?.error) {
                    dispatch(logout())
                }
            })
            }
       
    }, [authorized, dispatch, logout])
    //minimize tab-bar? 
    useEffect(() => {
        wrapperRef.current.scrollTo(0, 1)
    }, [location.pathname])

    useEffect(() => {
        checkAuthUser()
    }, [checkAuthUser])


    return (
        <ContextLayout>
            <FlipMessage/>
            <ParentLayout authorized={authorized}>
                <section className={'app'}>
                    <Progress/>
                    <Loader/>
                    <Aside/>
                    <section ref={wrapperRef} className={`wrapper`}>
                        <Header/>
                        <main className={`main ${shortedMainCondition && 'shorted'}`}>
                            {route}
                            {displayJoinButton && <PrivateBetaButton/>}
                        </main>
                        <Footer/>
                    </section>
                </section>
                
            </ParentLayout>
        </ContextLayout>
    )
}

export default App