import React from 'react'
import JoinIcon from '@pages/Home/components/Icons/JoinIcon'

const PrivateBetaButton = () => {
    return (
        <a href={`private_beta`} className={`private__button join`}>
            <JoinIcon/>
        </a>
    )
}

export default PrivateBetaButton