import React, {useState} from 'react'
import {PrivateBetaContext} from '@context/context'

const PrivateBetaLayout = ({children}) => {
    const [step, setStep] = useState(0)
    const increaseStep = () => {
        setStep(prev => ++prev)
    }
    const decreaseStep = () => {
        setStep(prev => --prev)
    }
    return (
        <PrivateBetaContext.Provider value={{
            step, setStep, increaseStep, decreaseStep
        }}>
            {children}
        </PrivateBetaContext.Provider>
    )
}

export default PrivateBetaLayout