import React from 'react'
import ModalLayout from '@layouts/ModalLayout'
import DemoLayout from '@layouts/DemoLayout'
import MainLayout from '@layouts/MainLayout'
import PrivateBetaLayout from '@layouts/PrivateBetaLayout'
import AsideLayout from '@layouts/AsideLayout'

const ContextLayout = ({children}) => {
    return (
        <MainLayout>
            <ModalLayout>
                <AsideLayout>
                    <DemoLayout>
                        <PrivateBetaLayout>
                            {children}
                        </PrivateBetaLayout>
                    </DemoLayout>
                </AsideLayout>
            </ModalLayout>
        </MainLayout>
    )
}

export default ContextLayout