import React from 'react'
import SignUpCreateForm from '@components/Modals/SignUp/SignUpCreateForm'
import {useLocation} from 'react-router-dom'
import icon from '@img/RealizeMD white logo.svg'

const SignUpCreateStep = () => {
    const location = useLocation()
    const signInForm = localStorage?.signInForm ? JSON.parse(localStorage?.signInForm) : location.state?.values
    return (
        <div className={`sign-up__intro`}>
            <img src={icon} alt={''} className={`sign-up__logo`}/>
            <h2 className={`sign-up__title create`}>Create Password</h2>
            <p className={`sign-up__subtitle create first`}>
                <a href={`mailto:${signInForm?.email}`}>{signInForm?.email}</a> has been verified successfully!
            </p>
            <p className={`sign-up__subtitle create`}>
                Choose a password for your account
            </p>
            <p className={`sign-up__description create`}>
                Password must be at least 8 characters long and include
                an uppercase character and a number or symbol.
            </p>
            <SignUpCreateForm/>
        </div>
    )
}

export default SignUpCreateStep