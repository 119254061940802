import {createTheme} from '@mui/material'
import {MOBILE_WIDTH} from '@root/config'

export const progressTheme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    width: '116px !important',
                    height: '116px !important',
                    color: '#DFB729',
                    background: 'radial-gradient(50% 50% at 50% 50%, #383841 0%, #22222B 100%)',
                    border: '5px solid #22222B',
                    borderRadius: '100%',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.3)'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#DDDDDD',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: 0,
                    '&.value': {
                        fontSize: 26
                    },
                    '&.status': {
                        fontSize: 22,
                        marginTop: 35,
                        textAlign: 'center'
                    },
                    [`@media(max-width: ${MOBILE_WIDTH}px)`]: {
                        '&.status': {
                            maxWidth: 272,
                            fontSize: 18,
                            marginTop: 29
                        }
                    }
                }
            }
        }
    }
})