import React, {useContext} from 'react'
import SignUpInputForm from '@components/Modals/SignUp/SignUpInputForm'
import {ModalContext} from '@context/context'
import icon from '@img/RealizeMD white logo.svg'

const SignUpIntro = () => {
    const {signInProps, signUpProps} = useContext(ModalContext)
    const closeModalHandler = () => {
        signUpProps.setSignUpActive(false)
        signInProps.setSignInActive(true)
    }
    return (
        <div className={`sign-up__intro`}>
            <img src={icon} alt={''} className={`sign-up__logo`}/>
            <h2 className={`sign-up__title input`}>Sign Up</h2>
            <SignUpInputForm/>
            <p className={`sign-up__footer`}>
                Already have an account?
                <button onClick={closeModalHandler}>
                    Log In
                </button>
            </p>
        </div>
    )
}

export default SignUpIntro