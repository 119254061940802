import React from 'react'

const PortfolioIcon = () => {
    return (
        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" labelledby='PortfolioButton PortfolioButtonDesc' role="img">
            <title id="PortfolioButton">Button redirects to Portfolio page</title>
            <desc id="PortfolioButtonDesc">An illustrated button redirects to Portfolio page</desc>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.64714 0C1.16242 0 5.92596e-05 1.3346 5.92596e-05 2.926L4.45946e-05 5.22868C1.40664e-05 9.38352 -4.52427e-05 17.4572 5.92596e-05 17.9732C0.000128619 18.3157 0.0217189 18.6204 0.0997476 18.9175C0.178735 19.2182 0.311031 19.4943 0.513567 19.789C1.05699 20.5795 1.7998 21 2.67377 21H18.9687C20.1482 21 21.1556 20.1549 21.5012 18.9408C21.8104 17.8547 23.6644 11.2525 23.883 10.4629C24.1278 9.57854 23.985 8.63781 23.487 7.88296C22.9876 7.1259 22.2066 6.67841 21.3445 6.67841H20.4777V5.32809C20.4777 3.74156 19.3144 2.40802 17.8253 2.40802H11.6815C11.3889 2.40802 11.1009 2.27814 10.8827 2.04015L9.81924 0.862005C9.32269 0.309284 8.65562 0 7.94205 0H2.64714ZM1.50681 2.926C1.50681 2.18655 2.04367 1.62907 2.64181 1.62907H7.94205C8.23242 1.62907 8.51594 1.75686 8.74158 1.9977L9.80429 3.17505C10.3008 3.72779 10.9679 4.03709 11.6815 4.03709H17.8306C18.4356 4.03709 18.9709 4.59621 18.9709 5.32809V6.67841H5.0176C3.83851 6.67841 2.83103 7.52298 2.48521 8.7312C2.33045 9.27192 1.92267 10.7201 1.55389 12.032L1.50148 12.2184V3.37918L1.5021 3.37507C1.50302 3.36852 1.50358 3.3632 1.50389 3.36002C1.5045 3.3537 1.50482 3.34861 1.50496 3.34616C1.50567 3.33413 1.50586 3.31833 1.50595 3.31048C1.50648 3.26568 1.50681 3.15061 1.50681 2.926ZM3.92836 9.20604C4.08309 8.65092 4.52742 8.30748 5.01227 8.30748H21.3391C21.698 8.30748 22.03 8.49015 22.249 8.82303L22.2507 8.82567C22.4803 9.16643 22.5452 9.58739 22.4289 9.99481C22.2848 10.5 20.2391 17.7842 20.0473 18.4724C19.8925 19.0275 19.4482 19.3709 18.9634 19.3709H2.66844C2.30696 19.3709 1.96834 19.1852 1.73699 18.843C1.50982 18.4933 1.44154 18.0586 1.55762 17.647C1.70146 17.1369 3.73645 9.89458 3.92836 9.20604Z"
                  fill="#858798"/>
        </svg>
    )
}

export default PortfolioIcon