import React from 'react'
import FooterDivider from '@components/Footer/components/FooterDivider'

const Footer = ({closeHandler}) => {
    const followLink = () => {
        if (!closeHandler) {
            return
        }
        closeHandler()
    }
    return (
        <footer className={`footer`}>
            <div className={`footer__item`}>
                <p className={`footer__rights`}>© RealizeMD 2022 All Rights Reserved</p>
                <FooterDivider/>
                <a
                    href={`mailto:info@realizemd.com`}
                    target={'_blank'}
                    className={`footer__link`}
                    rel="noreferrer"
                >
                    info@realizemd.com
                </a>
            </div>
            <div className={`footer__item`}>
                <a onClick={followLink} href={'/terms_of_use'} className={`footer__link`}>Terms Of Use</a>
                <FooterDivider/>
                <a onClick={followLink} href={'/privacy_policy'} className={`footer__link`}>Privacy Policy</a>
            </div>
        </footer>
    )
}

export default Footer