import React from 'react'
import {useSelector} from 'react-redux'

const Loader = () => {
    const {loading} = useSelector(state => state.mainReducer)
    return (
        <div className={`loader modal${loading ? ' active' : ''}`}>
            <div className={`loader__body`}>
                <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     width="60px" height="60px" viewBox="0 0 53 40" xmlSpace="preserve">
                    <path
                        fill="#DFB729"
                        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
                    >
                        <animateTransform
                            attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 25 25"
                            to="360 25 25"
                            dur="0.6s"
                            repeatCount="indefinite"
                        />
                    </path>
                </svg>
            </div>
        </div>
    )
}

export default Loader