import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Form, Formik} from 'formik'
import RmdTextField from '@components/Custom/RMDTextField/RMDTextField'
import CreatePasswordSchema from '@validation/createPasswordSchema'
import {useDispatch} from 'react-redux'
import {changeUserPassword, signIn} from '@store/actions/authActions'
import {useLocation, useNavigate} from 'react-router-dom'
import {ModalContext} from '@context/context'
import {modalsSlice} from '@store/reducers/modalsSlice'
import * as _ from 'lodash'

const SignUpCreateForm = () => {
    const {signUpProps: {signUpActive, setSignUpActive}} = useContext(ModalContext)
    const [error, setError] = useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let formRef = useRef(null)
    const {setSignUpStep} = modalsSlice.actions
    const signInSubmit = (values, {setSubmitting}) => {
        const valuesForm = JSON.parse(localStorage?.signInForm) || location.state?.values
        if (_.isEmpty(valuesForm)) {
            return
        }
        let submitForm = {username: valuesForm?.username, password: values.password, code: valuesForm?.code}
        setSubmitting(true)
        dispatch(changeUserPassword(submitForm))
            .then(res => {
                if (res?.error?.message) {
                    setTimeout(() => {
                        setSubmitting(false)
                        setError(res?.error?.message)
                    }, 0)
                    return
                }
                submitForm = {
                    email: valuesForm?.email,
                    password: values.password
                }
                dispatch(signIn(submitForm))
                    .then(() => {
                        navigate('/anonymization', {
                            state: {}
                        })
                        localStorage.removeItem('signInForm')
                        localStorage.removeItem('signUpStep')
                        localStorage.removeItem('signInStep')
                        dispatch(setSignUpStep(0))
                        setSignUpActive(false)
                    })
            })
            .catch((err) => {
                console.log(err)
                setSubmitting(false)
            })
    }
    const changeField = useCallback((handleChange, values, setValues) => (event) => {
        setError(null)
        const {value, name} = event.target
        values[name] = value
        values[`hidden_${name.toLowerCase()}`] = [...value].map((char, index) => {
            if (index + 1 !== value.length) {
                char = '*'
            }
            return char
        }).join('')
        setValues(values)
    },[])
    useEffect(() => {
        formRef?.current?.resetForm()
    }, [signUpActive])
    return (
        <Formik
            initialValues={{confirm_password: '', password: ''}}
            validationSchema={CreatePasswordSchema}
            onSubmit={signInSubmit}
            innerRef={(ref) => {
                formRef = ref
            }}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    setValues,
                    handleBlur
                } = props
                return (
                    <Form noValidate={true} onSubmit={handleSubmit}>
                        <div className={`sign-up__label create`}>
                            <RmdTextField
                                label={'Password'}
                                type={'password'}
                                name={'password'}
                                value={values?.password || ''}
                                onChange={changeField(handleChange, values, setValues)}
                                onBlur={handleBlur}
                                error={touched?.password && errors?.password}
                                className={`sign-up__field${touched?.password && errors?.password ? ' error' : ''}`}
                            />
                        </div>
                        <div className={`sign-up__label create`}>
                            <RmdTextField
                                label={'Confirm password'}
                                type={'password'}
                                name={'confirm_password'}
                                value={values?.confirm_password || ''}
                                onChange={changeField(handleChange, values, setValues)}
                                onBlur={handleBlur}
                                error={error || (touched?.confirm_password && errors?.confirm_password)}
                                className={`sign-up__field${touched?.confirm_password && errors?.confirm_password ? ' error' : ''}`}
                            />
                        </div>
                        <button
                            type={'submit'}
                            disabled={isSubmitting}
                            className={`sign-up__button sign`}
                        >
                            Sign Up
                        </button>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SignUpCreateForm