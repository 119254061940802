import {createSlice} from '@reduxjs/toolkit'
import {checkCorrectFace} from '@store/actions/mainActions'
import {getAnonymizationCandidates, postAnonymizationVideoPostCard } from '@store/actions/anonymizationActions'
import {getPostCards} from '@store/actions/portfolioActions'
import {getDemoCandidates} from '@store/actions/demoActions'

const initialState = {
    loading: false,
    progressing: false,
    currentSize: 1920,
    videoLoading:false,
}

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        clearAll(state) {
            state.loading = false
            state.progressing = false
            state.currentSize = 1920
            state.videoLoading = false
        },
        showLoader(state) {
            state.loading = true
        },
        hideLoader(state) {
            state.loading = false
        },
        setCurrentSize(state, action) {
            state.currentSize = action.payload
        }
    },
    extraReducers: {
        [checkCorrectFace.pending]: (state) => {
            state.loading = !state.loading
        },
        [checkCorrectFace.fulfilled]: (state) => {
            state.loading = false
        },
        [checkCorrectFace.rejected]: (state) => {
            state.loading = false
        },
        [getAnonymizationCandidates.pending]: (state) => {
            state.progressing = !state.loading
        },
        [getAnonymizationCandidates.fulfilled]: (state) => {
            state.progressing = false
        },
        [getAnonymizationCandidates.rejected]: (state) => {
            state.progressing = false
        },
        [postAnonymizationVideoPostCard.pending]:(state) =>{
            state.videoLoading = true
        },
        [postAnonymizationVideoPostCard.fulfilled]:(state) =>{
            state.videoLoading = false
        },
        [postAnonymizationVideoPostCard.rejected]:(state) =>{
            state.videoLoading = false
        },
        [getPostCards.pending]: (state) => {
            state.loading = !state.loading
        },
        [getPostCards.fulfilled]: (state) => {
            state.loading = false
        },
        [getPostCards.rejected]: (state) => {
            state.loading = false
        },
        [checkCorrectFace.pending]: (state) => {
            state.loading = !state.loading
        },
        [checkCorrectFace.fulfilled]: (state) => {
            state.loading = false
        },
        [checkCorrectFace.rejected]: (state) => {
            state.loading = false
        },
        [getDemoCandidates.pending]: (state) => {
            state.progressing = !state.loading
        },
        [getDemoCandidates.fulfilled]: (state) => {
            state.progressing = false
        },
        [getDemoCandidates.rejected]: (state) => {
            state.progressing = false
        }
    }
})

const {actions} = mainSlice;
export const {showLoader, hideLoader, setCurrentSize} = actions;
export default mainSlice.reducer;