import {createContext} from 'react'

export const MainContext = createContext({})

export const ModalContext = createContext({})

export const DemoContext = createContext({})

export const PrivateBetaContext = createContext({})

export const AsideContext = createContext({})