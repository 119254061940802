import {createSlice} from '@reduxjs/toolkit'
import demoSlice from './demoSlice'

const initialState = {
    signUpStep: Number(localStorage?.signUpStep) || 0,
    signInStep: Number(localStorage?.signInStep) || 0,
    videoControls:{
        modalPlay:false,
        modalTimeline:0,
        modalBufferLoading:false,
    }
}

export const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        setSignUpStep(state, action) {
            state.signUpStep = action.payload
            localStorage.signUpStep = state.signUpStep
        },
        increaseSignUpStep(state) {
            state.signUpStep += 1
            localStorage.signUpStep = state.signUpStep
        },
        decreaseSignUpStep(state) {
            state.signUpStep -= 1
            localStorage.signUpStep = state.signUpStep
        },
        setSignInStep(state, action) {
            state.signInStep = action.payload
            localStorage.signInStep = state.signUpStep
        },
        increaseSignInStep(state) {
            state.signInStep += 1
            localStorage.signInStep = state.signUpStep
        },
        decreaseSignInStep(state) {
            state.signInStep -= 1
            localStorage.signInStep = state.signUpStep
        },
        setModalPlay(state, action) {
            state.videoControls.modalPlay = action.payload
        },
        setModalTimeline(state, action) {
            state.videoControls.modalTimeline = action.payload
        },
        setModalBufferLoading(state, action) {
            state.videoControls.modalBufferLoading = action.payload
        },
        
    }
})

export default modalsSlice.reducer  
export const actions = demoSlice.actions