import {combineReducers} from 'redux'
import authReducer from '@store/reducers/authSlice'
import modalsReducer from '@store/reducers/modalsSlice'
import anonymizationReducer from '@store/reducers/anonymizationSlice'
import demoReducer from '@store/reducers/demoSlice'
import mainReducer from '@store/reducers/mainSlice'
import portfolioReducer from '@store/reducers/portfolioSlice'

export const rootReducer = combineReducers({
    mainReducer,
    authReducer,
    modalsReducer,
    anonymizationReducer,
    demoReducer,
    portfolioReducer
})