import React, {useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import SignInInputStep from '@components/Modals/SignIn/Steps/SignInInputStep'
import SignInCreateStep from '@components/Modals/SignIn/Steps/SignInCreateStep'

const useSignIn = () => {
    const {signInStep} = useSelector(state => state.modalsReducer)
    const steps = useMemo(() => {
        return [<SignInInputStep/>, <SignInCreateStep/>]
    }, [])
    const currentStep = useCallback(() => steps[signInStep], [steps, signInStep])
    return {
        currentStep
    }
}

export default useSignIn