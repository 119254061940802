import React from 'react'
import SignInSignUpModal from '@components/Modals/SIgnInSignUp/SignInSignUpModal'

const UnauthorizedLayout = ({children}) => {
    return (
        <>
            {children}
            <SignInSignUpModal />
        </>
    )
}

export default UnauthorizedLayout