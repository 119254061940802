import React from 'react'
import ReactDOM from 'react-dom'
import ProvideWrapper from '@root/ProvideWrapper'
import App from '@root/App'
import '@scss/_index.scss'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
    <ProvideWrapper>
        <App/>
    </ProvideWrapper>,
    document.getElementById('app')
)

// service worker registration 
// serviceWorkerRegistration.register();