import React from 'react'

const AuthorizedLayout = ({children}) => {
    return (
        <>
            {children}
        </>
    )
}

export default AuthorizedLayout