import React from 'react'
import {Routes, Route} from 'react-router-dom'
import HomePage from '@pages/Home/HomePage'
// import AnonymizationPairsPage from '@pages/Anonymization/AnonymizationPairsPage'
// import DemoPage from '@pages/Demo/DemoPage'
// import PortfolioPage from '@pages/Portfolio/PortfolioPage'
// import PrivateBetaPage from '@pages/PrivateBeta/PrivateBetaPage'
// import TermsPage from '@pages/Terms/TermsPage'
// import Progress from '@root/components/Progress/Progress'
// import PrivacyPage from '@pages/Privacy/PrivacyPage'

const PrivacyPage = React.lazy(()=>import('@pages/Privacy/PrivacyPage'))
const TermsPage = React.lazy(()=>import('@pages/Terms/TermsPage'))
const PrivateBetaPage = React.lazy(()=>import('@pages/PrivateBeta/PrivateBetaPage'))
const PortfolioPage = React.lazy(()=>import('@pages/Portfolio/PortfolioPage'))
const DemoPage = React.lazy(()=>import('@pages/Demo/DemoPage'))
const AnonymizationPairsPage = React.lazy(()=>import('@pages/Anonymization/AnonymizationPairsPage'))

const AuthorizedRoutes = () => {
    return (
        <Routes>
            <Route exact path={'/anonymization'}>
                <Route path={'pairs'} element={<React.Suspense fallback={<div>...loading</div>}><AnonymizationPairsPage/></React.Suspense>}/>
                <Route index element={<React.Suspense fallback={<div>...loading</div>}><AnonymizationPairsPage/></React.Suspense>}/>
            </Route>
            <Route exact path={'/portfolio'} element={<React.Suspense fallback={<div>...loading</div>}><PortfolioPage/></React.Suspense>}/>
            <Route exact path={'/demo'} element={<React.Suspense fallback={<div>...loading</div>}><DemoPage/></React.Suspense>}/>
            <Route exact path={'/terms_of_use'} element={<React.Suspense fallback={<div>...loading</div>}><TermsPage/></React.Suspense>}/>
            <Route exact path={'/privacy_policy'} element={<React.Suspense fallback={<div>...loading</div>}><PrivacyPage/></React.Suspense>}/>
            <Route exact path={'/private_beta'} element={<React.Suspense fallback={<div>...loading</div>}><PrivateBetaPage/></React.Suspense>}/>
            <Route exact path={'/'} element={<HomePage/>}/>
            <Route path="*" element={<HomePage />} />
        </Routes>
    )
}

export default AuthorizedRoutes