import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_URL} from '@root/config'

export const getPostCards = createAsyncThunk(
    'portfolio/get_post_cards',
    async ({requestString, token}, thunkAPI) => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }
            const {data} = await axios.get(requestString, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const getOriginal = createAsyncThunk(
    'portfolio/get_original',
    async ({anonymization, token}, thunkAPI) => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }
            const {data} = await axios.get(`${API_URL}/api/v1/anonymization/${anonymization}/`, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const getVideoShare = createAsyncThunk(
    'portfolio/get_original',
    async ({anonymization, token}, thunkAPI) => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }
            const {data} = await axios.get(`${API_URL}/api/v1/share_video/${anonymization}/`, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return console.log(e)
        }
    }
)

export const deletePostCard = createAsyncThunk(
    'portfolio/delete',
    async ({uuid, token}, thunkAPI) => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }
            const {data} = await axios.delete(`${API_URL}/api/v1/postcard/${uuid}/`, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)