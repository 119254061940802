import React, {useContext} from 'react'
import {Form, Formik} from 'formik'
import RmdTextField from '@components/Custom/RMDTextField/RMDTextField'
import CreatePasswordSchema from '@validation/createPasswordSchema'
import {useDispatch} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import {confirmPassword} from '@store/actions/authActions'
import {ModalContext} from '@context/context'
import {modalsSlice} from '@store/reducers/modalsSlice'

const SignInCreateForm = ({code}) => {
    const {signInProps: {setSignInActive}} = useContext(ModalContext)
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {setSignInStep} = modalsSlice.actions
    const signInHandler = (values, {setSubmitting, setErrors}) => {
        setSubmitting(true)
        const submitForm = {
            username: location.state?.values?.username,
            password: values.confirm_password,
            code
        }
        dispatch(confirmPassword(submitForm))
            .then(res => {
                if (res?.error) {
                    setSubmitting(false)
                    setErrors({
                        password: res?.payload?.message,
                        confirm_password: res?.payload?.message
                    })
                    return
                }
                setTimeout(() => {
                    navigate(location.pathname, {
                        state: {}
                    })
                    dispatch(setSignInStep(0))
                }, 0)
                setSignInActive(false)
                localStorage.removeItem('signInStep')
            })
    }
    return (
        <Formik
            initialValues={{confirm_password: '', password: ''}}
            validationSchema={CreatePasswordSchema}
            onSubmit={signInHandler}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    handleBlur
                } = props
                return (
                    <Form noValidate={true} onSubmit={handleSubmit}>
                        <div className={`sign-in__label create`}>
                            <RmdTextField
                                label={'Password'}
                                type={'password'}
                                name={'password'}
                                value={values?.password || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched?.password && errors?.password}
                                className={`sign-in__field${touched?.password && errors?.password ? ' error' : ''}`}
                            />
                        </div>
                        <div className={`sign-in__label create`}>
                            <RmdTextField
                                label={'Confirm password'}
                                type={'password'}
                                name={'confirm_password'}
                                value={values?.confirm_password || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched?.confirm_password && errors?.confirm_password}
                                className={`sign-in__field${touched?.confirm_password && errors?.confirm_password ? ' error' : ''}`}
                            />
                        </div>
                        <button
                            type={'submit'}
                            disabled={isSubmitting}
                            className={`sign-in__button save`}
                        >
                            Save password
                        </button>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SignInCreateForm