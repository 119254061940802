import React from 'react'

const RmdCheckbox = ({checked, onChange, name, label, error}) => {
    return (
        <label className={`checkbox__label${error ? ' error' : ''}`}>
            <input
                onChange={onChange}
                type={'checkbox'}
                name={name}
                checked={checked}
            />
            <span/>
            <p className={`checkbox__title`}>{label}</p>
        </label>
    )
}

export default RmdCheckbox