import React, {useContext} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import AsidePointItem from '@components/Aside/components/AsidePointItem'
import AsideExitIcon from '@components/Aside/icons/AsideExitIcon'
import {useNavigate} from 'react-router-dom'
import {authSlice} from '@store/reducers/authSlice'
import RMDAsideTooltip from '@components/Custom/RMDTooltip/RMDAsideTooltip'
import {AsideContext} from '@context/context'

const Aside = () => {
    const {authorized} = useSelector(state => state.authReducer)
    const {filteredPoints, shortName, fullName} = useContext(AsideContext)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {logout} = authSlice.actions
    const logoutHandler = () => {
        navigate('/')
        dispatch(logout())
    }
    return (
        <aside className={`aside`}>
            <div className={`aside__intro${authorized ? ' active' : ''}`}>
                {authorized && (
                    <div className={`aside__profile`}>
                        <RMDAsideTooltip
                            title={fullName ?? ''}
                            placement={'right'}
                            arrow={true}
                        >
                            <span>{shortName}</span>
                        </RMDAsideTooltip>
                    </div>
                )}
                <div className={`aside__point-list`}>
                    {filteredPoints.map(point => (
                        <AsidePointItem
                            point={point}
                            key={point.id}
                        />
                    ))}
                </div>
                {authorized && (
                    <button onClick={logoutHandler} className={`aside__exit`}>
                        <RMDAsideTooltip
                            title={'Log Out'}
                            placement={'right'}
                            arrow={true}
                        >
                            <span><AsideExitIcon/></span>
                        </RMDAsideTooltip>
                    </button>
                )}
            </div>
        </aside>
    )
}

export default Aside