import React from 'react'

const DemoIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" labelledby="DemoButton DemoButtonDesc" role="img">
            <title id="DemoButton">Button redirects to demo page</title>
            <desc id="DemoButtonDesc">An illustrated button redirects to Demo page</desc>
            <path
                d="M21.1627 14.7819C20.9406 14.7823 20.7278 14.8706 20.5708 15.0276C20.4138 15.1846 20.3255 15.3975 20.3251 15.6195V17.4669C20.3243 18.2247 20.0229 18.9512 19.4871 19.4871C18.9512 20.0229 18.2247 20.3243 17.4669 20.3251H15.6195C15.5079 20.3226 15.397 20.3424 15.2932 20.3833C15.1894 20.4243 15.0948 20.4855 15.015 20.5635C14.9352 20.6415 14.8718 20.7347 14.8285 20.8375C14.7852 20.9404 14.7629 21.0509 14.7629 21.1625C14.7629 21.274 14.7852 21.3845 14.8285 21.4874C14.8718 21.5902 14.9352 21.6834 15.015 21.7614C15.0948 21.8394 15.1894 21.9007 15.2932 21.9416C15.397 21.9825 15.5079 22.0023 15.6195 21.9998H17.4669C18.6679 21.996 19.8187 21.5172 20.668 20.668C21.5172 19.8187 21.996 18.6679 21.9998 17.4669V15.6195C21.9996 15.3975 21.9113 15.1847 21.7544 15.0276C21.5974 14.8706 21.3847 14.7823 21.1627 14.7819Z"
                fill="#858798"/>
            <path
                d="M6.38052 0.000216546H4.53308C3.33205 0.00398532 2.18129 0.482764 1.33202 1.33202C0.482764 2.18129 0.00398532 3.33205 0.000216546 4.53308V6.38052C-0.00232075 6.49209 0.0174613 6.60303 0.0584007 6.70684C0.0993401 6.81065 0.160611 6.90523 0.238615 6.98503C0.31662 7.06483 0.409784 7.12823 0.512635 7.17152C0.615487 7.21481 0.725952 7.23711 0.837543 7.23711C0.949133 7.23711 1.0596 7.21481 1.16245 7.17152C1.2653 7.12823 1.35847 7.06483 1.43647 6.98503C1.51447 6.90523 1.57575 6.81065 1.61668 6.70684C1.65762 6.60303 1.67741 6.49209 1.67487 6.38052V4.53308C1.67567 3.77528 1.97706 3.04875 2.51291 2.51291C3.04875 1.97706 3.77528 1.67567 4.53308 1.67487H6.38052C6.49209 1.67741 6.60303 1.65762 6.70684 1.61668C6.81065 1.57575 6.90523 1.51447 6.98503 1.43647C7.06483 1.35847 7.12823 1.2653 7.17152 1.16245C7.21481 1.0596 7.23711 0.949133 7.23711 0.837543C7.23711 0.725952 7.21481 0.615487 7.17152 0.512635C7.12823 0.409784 7.06483 0.31662 6.98503 0.238615C6.90523 0.160611 6.81065 0.0993401 6.70684 0.0584007C6.60303 0.0174613 6.49209 -0.00232075 6.38052 0.000216546Z"
                fill="#858798"/>
            <path
                d="M0.837326 14.7819C0.615341 14.7823 0.402553 14.8706 0.245627 15.0276C0.0887006 15.1847 0.000445603 15.3975 0.000216546 15.6195V17.4669C0.00398532 18.6679 0.482764 19.8187 1.33202 20.668C2.18129 21.5172 3.33205 21.996 4.53308 21.9998H6.38052C6.49209 22.0023 6.60303 21.9825 6.70684 21.9416C6.81065 21.9007 6.90523 21.8394 6.98503 21.7614C7.06483 21.6834 7.12823 21.5902 7.17152 21.4874C7.21481 21.3845 7.23711 21.274 7.23711 21.1625C7.23711 21.0509 7.21481 20.9404 7.17152 20.8375C7.12823 20.7347 7.06483 20.6415 6.98503 20.5635C6.90523 20.4855 6.81065 20.4243 6.70684 20.3833C6.60303 20.3424 6.49209 20.3226 6.38052 20.3251H4.53308C3.77528 20.3243 3.04875 20.0229 2.51291 19.4871C1.97706 18.9512 1.67567 18.2247 1.67487 17.4669V15.6195C1.67452 15.3975 1.58617 15.1846 1.42918 15.0276C1.27218 14.8706 1.05935 14.7823 0.837326 14.7819Z"
                fill="#858798"/>
            <path
                d="M21.1627 7.21807C21.3847 7.21772 21.5974 7.12936 21.7544 6.97235C21.9113 6.81534 21.9996 6.60251 21.9998 6.38052V4.53308C21.996 3.33205 21.5172 2.18129 20.668 1.33202C19.8187 0.482764 18.6679 0.00398532 17.4669 0.000216546H15.6195C15.5079 -0.00232075 15.397 0.0174613 15.2932 0.0584007C15.1894 0.0993401 15.0948 0.160611 15.015 0.238615C14.9352 0.31662 14.8718 0.409784 14.8285 0.512635C14.7852 0.615487 14.7629 0.725952 14.7629 0.837543C14.7629 0.949133 14.7852 1.0596 14.8285 1.16245C14.8718 1.2653 14.9352 1.35847 15.015 1.43647C15.0948 1.51447 15.1894 1.57575 15.2932 1.61668C15.397 1.65762 15.5079 1.67741 15.6195 1.67487H17.4669C18.2247 1.67567 18.9512 1.97706 19.4871 2.51291C20.0229 3.04875 20.3243 3.77528 20.3251 4.53308V6.38052C20.3255 6.60255 20.4138 6.81538 20.5708 6.97238C20.7278 7.12937 20.9406 7.21772 21.1627 7.21807Z"
                fill="#858798"/>
            <path
                d="M11 3.00264C10.2377 3.00264 9.49252 3.22869 8.85869 3.6522C8.22486 4.07571 7.73085 4.67766 7.43913 5.38193C7.14741 6.08621 7.07109 6.86117 7.2198 7.60882C7.36852 8.35647 7.7356 9.04323 8.27463 9.58226C8.81366 10.1213 9.50042 10.4884 10.2481 10.6371C10.9957 10.7858 11.7707 10.7095 12.475 10.4178C13.1792 10.126 13.7812 9.63203 14.2047 8.9982C14.6282 8.36437 14.8543 7.61919 14.8543 6.85689C14.853 5.83507 14.4465 4.85545 13.724 4.13292C13.0014 3.41038 12.0218 3.0039 11 3.00264ZM11 9.18114C10.5403 9.18114 10.0909 9.04482 9.70872 8.78943C9.3265 8.53404 9.02859 8.17104 8.85268 7.74634C8.67676 7.32164 8.63073 6.85431 8.72041 6.40345C8.8101 5.95259 9.03146 5.53845 9.35651 5.2134C9.68156 4.88835 10.0957 4.66699 10.5466 4.57731C10.9974 4.48762 11.4647 4.53365 11.8894 4.70957C12.3141 4.88549 12.6771 5.18339 12.9325 5.56561C13.1879 5.94783 13.3242 6.3972 13.3242 6.85689C13.3236 7.47311 13.0785 8.06389 12.6427 8.49962C12.207 8.93535 11.6162 9.18045 11 9.18114Z"
                fill="#858798"/>
            <path
                d="M11.9237 12.0103H10.0763C7.07039 12.0134 4.62272 14.4615 4.61969 17.4669C4.61969 17.6889 4.70789 17.9019 4.86488 18.0588C5.02186 18.2158 5.23479 18.304 5.4568 18.304C5.67882 18.304 5.89174 18.2158 6.04873 18.0588C6.20572 17.9019 6.29391 17.6889 6.29391 17.4669C6.29517 16.4642 6.69409 15.5029 7.40316 14.7939C8.11223 14.0849 9.07356 13.6861 10.0763 13.685H11.9237C12.9264 13.6861 13.8878 14.0849 14.5968 14.7939C15.3059 15.5029 15.7048 16.4642 15.7061 17.4669C15.7061 17.6889 15.7943 17.9019 15.9513 18.0588C16.1083 18.2158 16.3212 18.304 16.5432 18.304C16.7652 18.304 16.9781 18.2158 17.1351 18.0588C17.2921 17.9019 17.3803 17.6889 17.3803 17.4669C17.3773 14.4615 14.9296 12.0134 11.9237 12.0103Z"
                fill="#858798"/>
        </svg>
    )
}

export default DemoIcon