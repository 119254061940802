import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ModalContext } from "@context/context";
import ExitIcon from "@components/Icons/ExitIcon";
import useSignUp from "@hooks/useSignUp";
import { modalsSlice } from "@store/reducers/modalsSlice";
import useSignIn from "@hooks/useSignIn";
import { authSlice } from "@store/reducers/authSlice";

const SignInSugnUpModal = () => {
  const [signInOrSignUp, setSignInOrSignUp] = useState();
  const dispatch = useDispatch();
  const { signUpProps, signInProps } = useContext(ModalContext);
  const { currentStep } = useSignUp();
  const currentStepSignIn = useSignIn();
  const { setError } = authSlice.actions;
  const { setSignUpStep, setSignInStep } = modalsSlice.actions;
  
  const closeModalHandler = () => {
    dispatch(setSignUpStep(0));
    dispatch(setSignInStep(0));
    signUpProps.setSignUpActive(false);
    signInProps.setSignInActive(false);
    signInProps.signInActive && dispatch(setError(null));
  };
  const signStep = () => {
    if (signInProps.signInActive) return currentStepSignIn.currentStep();
    if (signUpProps.signUpActive) return currentStep();
  };

  useEffect(() => {
    signInProps.signInActive && setSignInOrSignUp("sign-in");
    signUpProps.signUpActive && setSignInOrSignUp("sign-up");
  }, [signInProps.signInActive, signUpProps.signUpActive]);
  return (
    <div
      onClick={closeModalHandler}
      className={`${signInOrSignUp} modal ${
        (signUpProps.signUpActive || signInProps.signInActive) && "active"
      }`}
    >
      <div
        className={`${signInOrSignUp}__body`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={closeModalHandler}
          className={`${signInOrSignUp}__button exit`}
        >
          <ExitIcon />
        </button>
        <div className={`${signInOrSignUp}__content`}>{signStep()}</div>
      </div>
    </div>
  );
};

export default SignInSugnUpModal;
