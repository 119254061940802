import {createAsyncThunk} from '@reduxjs/toolkit'
import {API_URL} from '@root/config'
import axios from 'axios'

export const getAnonymizationCandidates = createAsyncThunk(
    'anonymization/get_candidates',
    async (form, thunkAPI) => {
        try {
            const {token, ...other} = form
            const headers = {
                'Authorization': `Bearer ${token}`
            }
            const {data} = await axios.post(`${API_URL}/api/v1/anonymize/`, other, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const getAnonymizationPostCard = createAsyncThunk(
    'anonymization/get_postcard',
    async (form, thunkAPI) => {
        try {
            const {token, ...other} = form
            const headers = {
                'Authorization': `Bearer ${token}`
            }
            const {data} = await axios.post(`${API_URL}/api/v1/makepostcard/`, other, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)


export const postAnonymizationVideoPostCard = createAsyncThunk(
    'anonymization/post_video-Postcard',
    async (form, thunkAPI) => {
        try {
            const {token, ...other} = form
            const headers = {
                'Authorization': `Bearer ${token}`
            }
            const {data} = await axios.post(`${API_URL}/api/v1/makepostcard_video/`, other, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {   
            return thunkAPI.rejectWithValue(e)
        }
    }
)