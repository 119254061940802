import React from 'react'

const SignFeedbackIcon = () => {
    return (
        <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.0417 0H3.95833C2.9089 0.00108263 1.90282 0.360601 1.16076 0.999695C0.418698 1.63879 0.00125705 2.50528 0 3.40909L0 11.5909C0.00125705 12.4947 0.418698 13.3612 1.16076 14.0003C1.90282 14.6394 2.9089 14.9989 3.95833 15H15.0417C16.0911 14.9989 17.0972 14.6394 17.8392 14.0003C18.5813 13.3612 18.9987 12.4947 19 11.5909V3.40909C18.9987 2.50528 18.5813 1.63879 17.8392 0.999695C17.0972 0.360601 16.0911 0.00108263 15.0417 0ZM3.95833 1.36364H15.0417C15.5157 1.36444 15.9786 1.4874 16.3708 1.71668C16.7631 1.94597 17.0666 2.2711 17.2425 2.65023L11.1799 7.87227C10.7338 8.25499 10.1297 8.46986 9.5 8.46986C8.8703 8.46986 8.26624 8.25499 7.82008 7.87227L1.7575 2.65023C1.93337 2.2711 2.23694 1.94597 2.62916 1.71668C3.02138 1.4874 3.4843 1.36444 3.95833 1.36364ZM15.0417 13.6364H3.95833C3.32844 13.6364 2.72435 13.4209 2.27895 13.0373C1.83356 12.6537 1.58333 12.1334 1.58333 11.5909V4.43182L6.70067 8.83636C7.44375 9.47472 8.45045 9.83321 9.5 9.83321C10.5495 9.83321 11.5563 9.47472 12.2993 8.83636L17.4167 4.43182V11.5909C17.4167 12.1334 17.1664 12.6537 16.721 13.0373C16.2756 13.4209 15.6716 13.6364 15.0417 13.6364Z"
                fill="white"/>
        </svg>
    )
}

export default SignFeedbackIcon