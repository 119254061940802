import React, {useMemo, useEffect, useRef, useContext} from 'react'
import {Form, Formik} from 'formik'
import RmdTextField from '@components/Custom/RMDTextField/RMDTextField'
import RmdCheckbox from '@components/Custom/RMDCheckbox/RMDCheckbox'
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {modalsSlice} from '@store/reducers/modalsSlice'
import {SignUpSchema} from '@validation/signInSchema'
import {signUp} from '@store/actions/authActions'
import * as _ from 'lodash'
import {ModalContext} from '@context/context'

const SignUpInputForm = () => {
    const {signUpProps: {signUpActive}} = useContext(ModalContext)
    const {confirmed} = useSelector(state => state.modalsReducer)
    const {increaseSignUpStep} = modalsSlice.actions
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    let formikRef = useRef(null)
    const defaultValues = useMemo(() => localStorage?.signInForm ? JSON.parse(localStorage?.signInForm) : location.state?.values, [location.state?.values])
    const signUpHandler = async (values, {setSubmitting, setErrors}) => {
        if (localStorage?.signInForm && !_.isEmpty(localStorage?.signInForm)) {
            setTimeout(() => {
                dispatch(increaseSignUpStep())
            }, 0)
            return
        }
        setSubmitting(true)
        const newValues = {...values, username: values?.email?.replace('@', '#')}
        setTimeout(() => {
            dispatch(signUp(newValues))
                .then((res) => {
                    if (!_.isEmpty(res?.error)) {
                        setErrors({email: res?.payload?.message})
                        return
                    }
                    dispatch(increaseSignUpStep())
                    localStorage.setItem('signInForm', JSON.stringify(newValues))
                    setTimeout(() => {
                        navigate('/', {
                            state: {
                                values: newValues
                            }
                        })
                    }, 0)
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }, 0)
    }
    useEffect(() => {
        if (signUpActive) {
            return
        }
        formikRef.resetForm({})
    }, [signUpActive])
    return (
        <Formik
            initialValues={{
                email: defaultValues?.email || '',
                first_name: defaultValues?.first_name || '',
                last_name: defaultValues?.last_name || '',
                clinic_name: defaultValues?.clinic_name || '',
                agree: defaultValues?.agree || false
            }}
            validationSchema={!confirmed && SignUpSchema}
            innerRef={(ref) => {
                formikRef = ref
            }}
            onSubmit={signUpHandler}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur
                } = props
                return (
                    <Form noValidate={true}>
                        <div className={`sign-up__label`}>
                            <RmdTextField
                                label={'Clinic name'}
                                type={'text'}
                                name={'clinic_name'}
                                value={values?.clinic_name || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched?.clinic_name && errors?.clinic_name}
                                className={`sign-in__field${touched?.clinic_name && errors?.clinic_name ? ' error' : ''}`}
                            />
                        </div>
                        <div className={`sign-up__label double`}>
                            <RmdTextField
                                label={'First name'}
                                type={'text'}
                                name={'first_name'}
                                value={values?.first_name || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched?.first_name && errors?.first_name}
                                className={`sign-in__field${touched?.first_name && errors?.first_name ? ' error' : ''}`}
                            />
                            <RmdTextField
                                label={'Last name'}
                                type={'text'}
                                name={'last_name'}
                                value={values?.last_name || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched?.last_name && errors?.last_name}
                                className={`sign-in__field${touched?.last_name && errors?.last_name ? ' error' : ''}`}
                            />
                        </div>
                        <div className={`sign-up__label`}>
                            <RmdTextField
                                label={'Email address'}
                                type={'email'}
                                name={'email'}
                                value={values?.email || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched?.email && errors?.email}
                                className={`sign-in__field${touched?.email && errors?.email ? ' error' : ''}`}
                            />
                        </div>
                        <div className={`sign-up__label`}>
                            <RmdCheckbox
                                checked={values?.agree}
                                onChange={handleChange}
                                name={'agree'}
                                label={<>
                                    I agree to the <NavLink to={'/terms_of_use'}>Terms of Service</NavLink> and <NavLink
                                    to={'/privacy_policy'}>Privacy Policy</NavLink>
                                </>}
                                error={touched?.agree && errors?.agree}
                            />
                        </div>
                        <button
                            type={'submit'}
                            disabled={isSubmitting}
                            className={`sign-up__button login`}
                        >
                            Subscribe
                        </button>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SignUpInputForm