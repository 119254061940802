import React from 'react'

const ExitIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L15 15M2 15L15 2" stroke="#C1C1C1" strokeWidth="2.26087" strokeLinecap="round"/>
        </svg>
    )
}

export default ExitIcon