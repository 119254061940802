import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_URL} from '@root/config'

export const getDemoCandidates = createAsyncThunk(
    'demo/get_candidates',
    async (form, thunkAPI) => {
        try {
            const {token, ...other} = form
            const headers = token ? {
                'Authorization': `Bearer ${token}`
            } : {}
            const {data} = await axios.post(`${API_URL}/api/v1/anonymize_demo/`, other, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const getDemoPostcard = createAsyncThunk(
    'demo/get_candidates',
    async (form, thunkAPI) => {
        try {
            const {token, ...other} = form
            const headers = token ? {
                'Authorization': `Bearer ${token}`
            } : {}
            const {data} = await axios.post(`${API_URL}/api/v1/makepostcard_demo/`, other, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const getLandmarks = createAsyncThunk(
    'demo/get_landmarks',
    async (form, thunkAPI) => {
        try {
            const {token, uuid, area_name} = form
            const headers = token ? {
                'Authorization': `Bearer ${token}`
            } : {}
            const {data} = await axios.get(`${API_URL}/api/v1/landmarks/${uuid}/${area_name}/`, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)