import React from 'react'

const MobileAsideBackgroundIcon = () => {
    return (
        <svg width="310" height="344" viewBox="0 0 310 344" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.1">
                <path d="M95.4411 12.8555H73.36V34.8297H95.4411V12.8555Z" fill="#3B3B43"/>
                <path d="M41.4248 0H0V41.2244H41.4248V0Z" fill="#3B3B43"/>
                <path d="M79.0704 49.6875H49.9766V78.6795H79.0704V49.6875Z" fill="#3B3B43"/>
                <path d="M64.536 107.697H49.9631V343.888H64.536V107.697Z" fill="#3B3B43"/>
                <path
                    d="M274.201 310.823L245.211 227.92L246.006 227.739C260.068 223.308 272.824 215.514 283.156 205.038C301.161 187.241 310.164 165.124 310.164 138.687C310.153 123.212 305.806 108.047 297.611 94.8944C289.376 81.252 277.784 69.9217 263.929 61.972C250.245 53.8963 234.621 49.6518 218.711 49.6877H108.189V64.1902H218.672C239.033 64.1902 256.938 71.6749 272.389 86.6444C287.84 101.614 295.561 118.961 295.552 138.687C295.552 161.647 288 180.474 272.897 195.166C259.641 208.06 244.221 215.299 226.389 216.881L260.931 315.467C258.214 318.103 256.483 321.583 256.024 325.331C255.565 329.079 256.405 332.871 258.406 336.079C260.407 339.287 263.448 341.72 267.027 342.974C270.606 344.228 274.508 344.229 278.087 342.976C281.667 341.723 284.709 339.292 286.711 336.084C288.713 332.877 289.555 329.085 289.097 325.337C288.639 321.588 286.909 318.108 284.193 315.471C281.477 312.834 277.938 311.199 274.162 310.836L274.201 310.823ZM272.584 335.067C271.038 335.067 269.526 334.611 268.239 333.756C266.953 332.9 265.951 331.685 265.359 330.263C264.767 328.84 264.612 327.276 264.914 325.766C265.216 324.256 265.96 322.869 267.054 321.781C268.148 320.692 269.542 319.951 271.059 319.651C272.576 319.35 274.148 319.504 275.577 320.094C277.006 320.683 278.228 321.68 279.087 322.96C279.947 324.24 280.405 325.745 280.405 327.284C280.405 329.348 279.581 331.328 278.115 332.788C276.648 334.247 274.659 335.067 272.584 335.067Z"
                    fill="#3B3B43"
                />
                <path d="M93.6686 107.697H79.1086V343.888H93.6686V107.697Z" fill="#3B3B43"/>
                <path
                    d="M262.821 185.216C274.987 173.532 281.07 158.027 281.07 138.699C281.07 122.985 274.796 109.058 262.248 96.9165C249.7 84.7749 235.2 78.7214 218.75 78.756H108.189V93.1936H218.672C231.081 93.1936 242.144 97.8591 251.859 107.19C261.574 116.521 266.428 127.024 266.419 138.699C266.419 154.533 261.8 166.748 252.563 175.344C243.326 183.94 232.029 188.234 218.672 188.225H187.389L212.924 261.075C210.213 263.716 208.49 267.198 208.038 270.946C207.587 274.694 208.434 278.483 210.44 281.687C212.446 284.891 215.491 287.318 219.071 288.566C222.651 289.814 226.552 289.809 230.128 288.551C233.705 287.293 236.743 284.858 238.74 281.649C240.738 278.44 241.575 274.648 241.113 270.902C240.651 267.155 238.918 263.677 236.2 261.043C233.483 258.41 229.943 256.778 226.167 256.418L207.384 202.701H218.672C235.956 202.719 250.673 196.89 262.821 185.216ZM232.372 272.957C232.372 274.496 231.913 276.001 231.054 277.281C230.194 278.561 228.973 279.558 227.544 280.147C226.115 280.736 224.542 280.891 223.025 280.59C221.508 280.29 220.115 279.549 219.021 278.46C217.927 277.372 217.182 275.985 216.88 274.475C216.579 272.965 216.733 271.4 217.325 269.978C217.917 268.556 218.92 267.341 220.206 266.485C221.492 265.63 223.004 265.174 224.551 265.174C225.578 265.174 226.595 265.375 227.544 265.766C228.493 266.157 229.355 266.731 230.081 267.453C230.807 268.176 231.384 269.034 231.777 269.978C232.17 270.923 232.372 271.935 232.372 272.957Z"
                    fill="#3B3B43"
                />
                <path
                    d="M218.855 174.087C241.014 174.087 251.898 162.062 251.898 138.7C251.922 134.573 251.058 130.489 249.365 126.721C247.673 122.953 245.189 119.59 242.083 116.856C235.815 110.846 227.414 107.545 218.711 107.671H108.189V343.888H122.801V122.187H218.711C223.59 122.032 228.346 123.724 232.02 126.922C233.733 128.368 235.102 130.175 236.028 132.212C236.955 134.249 237.415 136.465 237.377 138.7C237.377 146.353 235.726 151.689 232.424 154.707C228.705 158.016 223.839 159.756 218.855 159.559H148.597L199.328 304.661C196.619 307.294 194.89 310.766 194.427 314.506C193.964 318.246 194.795 322.032 196.782 325.24C198.77 328.448 201.795 330.887 205.361 332.156C208.927 333.425 212.82 333.448 216.401 332.221C219.982 330.994 223.036 328.59 225.061 325.406C227.086 322.222 227.962 318.446 227.543 314.7C227.125 310.955 225.438 307.463 222.759 304.799C220.081 302.135 216.571 300.457 212.807 300.043L168.566 174.048L218.855 174.087ZM218.724 316.517C218.724 318.057 218.266 319.561 217.406 320.841C216.547 322.121 215.325 323.119 213.896 323.708C212.467 324.297 210.895 324.451 209.378 324.151C207.861 323.851 206.467 323.109 205.373 322.021C204.279 320.932 203.535 319.546 203.233 318.036C202.931 316.526 203.086 314.961 203.678 313.539C204.27 312.117 205.272 310.901 206.558 310.046C207.845 309.191 209.357 308.734 210.903 308.734C212.978 308.734 214.967 309.554 216.434 311.014C217.9 312.473 218.724 314.453 218.724 316.517Z"
                    fill="#3B3B43"
                />
            </g>
        </svg>
    )
}

export default MobileAsideBackgroundIcon