import {createAsyncThunk} from '@reduxjs/toolkit'
import {API_URL} from '@root/config'
import axios from 'axios'

export const checkCorrectFace = createAsyncThunk(
    'main/face_checking',
    async (form, thunkAPI) => {
        try {
            const {image, imageName, token} = form
            const headers = token ? {
                'Authorization': `Bearer ${token}`,
                'Content-Disposition': `attachment; filename=${imageName}`
            } : {}
            const formData = new FormData()
            formData.append('file', image)
            const {data} = await axios.post(`${API_URL}/api/v1/loadfile/`, formData, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const getSharePostCard = createAsyncThunk(
    'main/get_original',
    async ({uuid, token}, thunkAPI) => {
        try {
            const headers = token ? {
                'Authorization': `Bearer ${token}`
            } : {}
            const {data} = await axios.get(`${API_URL}/api/v1/share/${uuid}/`, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)


export const sendContact = createAsyncThunk(
    'main/send_contact',
    async (form, thunkAPI) => {
        try {
            const {token, ...other} = form
            const headers = token ? {
                'Authorization': `Bearer ${token}`
            } : {}
            const {data} = await axios.post(`${API_URL}/api/v1/contact/`, other, {headers})
            return thunkAPI.fulfillWithValue(data)
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)