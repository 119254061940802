import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    demoStep: 0,
    demoImage: null,
    demoError: null,
    demoResults: {},
    demoSelectedResultsPair: {},
    demoResultsPairIndex: 0,
    demoShareResults: {}
}

export const demoSlice = createSlice({
    name: 'demo',
    initialState,
    reducers: {
        clearAll(state) {
            state.demoStep = 0
            state.demoImage = null
            state.demoError = null
            state.demoResults = {}
            state.demoSelectedResultsPair = {}
            state.demoResultsPairIndex = 0
            state.demoShareResults = {}
        },
        increaseDemoStep(state) {
            state.demoStep += 1
        },
        decreaseDemoStep(state) {
            state.demoStep -= 1
        },
        setDemoStep(state, action) {
            state.demoStep = action.payload
        },
        setDemoImage(state, action) {
            state.demoImage = action.payload
        },
        setDemoError(state, action) {
            state.demoError = action.payload
        },
        setDemoResults(state, action) {
            state.demoResults = action.payload
        },
        setDemoSelectedResultsPair(state, action) {
            state.demoSelectedResultsPair = action.payload
        },
        setDemoResultsPairIndex(state, action) {
            state.demoResultsPairIndex = action.payload
        },
        setDemoShareResults(state, action) {
            state.demoShareResults = action.payload
        }
    },
    extraReducers: {}
})

export default demoSlice.reducer