import * as Yup from 'yup'

const CreatePasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password is too short, it must include at least 8 characters')
        .required('Required')
        .matches(
            /((?=.*[A-Z]){1})/,
            'Must contain at least one uppercase'
        )
        .matches(
            /((?=.*[0-9]){1})/,
            'Must contain at least one number'
        ),
    confirm_password: Yup.string()
        .min(8, 'Password is too short, it must include at least 8 characters')
        .required('Required')
        .matches(
            /((?=.*[A-Z]){1})/,
            'Must contain at least one uppercase'
        )
        .matches(
            /((?=.*[0-9]){1})/,
            'Must contain at least one number'
        )
        .test('match',
            `Passwords don’t match`,
            function (confirm_password) {
                return confirm_password === this.parent.password
            })
})

export default CreatePasswordSchema