import React, { useMemo, useState } from "react";
import { AsideContext } from "@context/context";
import HomeIcon from "@components/Aside/icons/HomeIcon";
import AnonymizationIcon from "@components/Aside/icons/AnonymizationIcon";
import PortfolioIcon from "@components/Aside/icons/PortfolioIcon";
import DemoIcon from "@components/Aside/icons/DemoIcon";
import SettingsIcon from "@components/Aside/icons/SettingsIcon";
import PrivateBetaIcon from "@components/Aside/icons/PrivateBetaIcon";
import { useSelector } from "react-redux";

const AsideLayout = ({ children }) => {
  const { authorized, user } = useSelector((state) => state.authReducer);
  const asidePoints = useMemo(
    () => [
      {
        id: 1,
        title: "Home page",
        icon: <HomeIcon />,
        path: "/",
        disabled: false,
        active: false,
      },
      {
        id: 2,
        title: (
          <>Pair anonymization {!authorized && <span>(subscription)</span>}</>
        ),
        icon: <AnonymizationIcon />,
        path: "/anonymization",
        disabled: !authorized,
        active: false,
      },
      {
        id: 3,
        title: "My portfolio",
        icon: <PortfolioIcon />,
        path: "/portfolio",
        disabled: false,
        active: false,
      },
      {
        id: 4,
        title: "single image Demo",
        icon: <DemoIcon />,
        path: "/demo",
        disabled: false,
        active: false,
      },
      // change disabled true for visability of menuItem
      {
        id: 5,
        title: "settings",
        icon: <SettingsIcon />,
        path: "/settings",
        disabled: true,
        active: false,
      },
      {
        id: 6,
        title: "private beta",
        icon: <PrivateBetaIcon />,
        path: "/private_beta",
        disabled: false,
        active: false,
      },
    ],
    [authorized]
  );
  const filteredPoints = authorized
    ? [...asidePoints]
    : [...asidePoints].filter((point) => ![3, 5].includes(point.id));
  const fullName = !_.isEmpty(user)
    ? [user["custom:first_name"], user["custom:last_name"]].join(" ")
    : "";
  const shortName = useMemo(
    () =>
      user["custom:first_name"]
        ?.charAt(0)
        ?.toUpperCase()
        ?.concat(user["custom:last_name"]?.charAt(0)?.toUpperCase()),
    [user]
  );
  const [activeAside, setActiveAside] = useState(false);
  return (
    <AsideContext.Provider
      value={{
        filteredPoints,
        asidePoints,
        fullName,
        shortName,
        activeAside,
        setActiveAside,
      }}
    >
      {children}
    </AsideContext.Provider>
  );
};

export default AsideLayout;
