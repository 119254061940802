import React, {useCallback, useEffect, useState} from 'react'
import {DemoContext} from '@context/context'

const DemoLayout = ({children}) => {
    const [facingMode, setFacingMode] = useState('user')
    const [modalActive, setModalActive] = useState(false)
    const [buffImg, setBuffImg] = useState(null)
    const clearData = useCallback(() => {
        setModalActive(false)
        setFacingMode('user')
    }, [])
    useEffect(() => {
        clearData()
        return () => {
            clearData()
        }
    }, [clearData])
    return (
        <DemoContext.Provider value={{
            modal: {modalActive, setModalActive},
            buff: {buffImg, setBuffImg},
            mode: {facingMode, setFacingMode}
        }}>
            {children}
        </DemoContext.Provider>
    )
}

export default DemoLayout