import React from 'react'

function FlipMessage() {
  return (
    <div className='message'>
        <div className='message__background'></div>
        <div className='message__main'> <span></span> </div></div>
  )
}

export default FlipMessage;