import React from 'react'
import {Route, Routes} from 'react-router-dom'
import HomePage from '@pages/Home/HomePage'
// import DemoPage from '@pages/Demo/DemoPage'
// import PrivateBetaPage from '@pages/PrivateBeta/PrivateBetaPage'
// import TermsPage from '@pages/Terms/TermsPage'
// import PrivacyPage from '@pages/Privacy/PrivacyPage'

const PrivacyPage = React.lazy(()=>import('@pages/Privacy/PrivacyPage'))
const TermsPage = React.lazy(()=>import('@pages/Terms/TermsPage'))
const PrivateBetaPage = React.lazy(()=>import('@pages/PrivateBeta/PrivateBetaPage'))
const DemoPage = React.lazy(()=>import('@pages/Demo/DemoPage'))
const UnauthorizedRoutes = () => {
    return (
        <Routes>
            <Route exact path={'/'} element={<HomePage/>}/>
            <Route exact path={'/demo'} element={<React.Suspense fallback={<div>...loading</div>}><DemoPage/></React.Suspense>}/>
            <Route exact path={'/demo'} element={<React.Suspense fallback={<div>...loading</div>}><DemoPage/></React.Suspense>}/>
            {/* <Route exact path={'/demo'} element={<DemoPage/>}/> */}
            <Route exact path={'/private_beta'} element={<React.Suspense fallback={<div>...loading</div>}><PrivateBetaPage/></React.Suspense>}/>
            <Route exact path={'/terms_of_use'} element={<React.Suspense fallback={<div>...loading</div>}><TermsPage/></React.Suspense>}/>
            <Route exact path={'/privacy_policy'} element={<React.Suspense fallback={<div>...loading</div>}><PrivacyPage/></React.Suspense>}/>
            <Route path="*" element={<HomePage />} />
        </Routes>
    )
}

export default UnauthorizedRoutes