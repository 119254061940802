import React from 'react'
import {createTheme, TextField, ThemeProvider} from '@mui/material'
import {MOBILE_WIDTH, TABLET_WIDTH} from '@root/config'

const theme = createTheme({
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    width: 660,
                    margin: '0',
                    letterSpacing: '0px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: '24px',
                    position: 'absolute',
                    bottom: -28,
                    '&.Mui-error': {
                        color: '#E53737'
                    },
                    [`@media(max-width: ${TABLET_WIDTH}px)`]: {
                        fontSize: 12,
                        lineHeight: '19px',
                        bottom: -19.41
                    },
                    [`@media(max-width: ${MOBILE_WIDTH}px)`]: {
                        maxWidth: '100%',
                        fontSize: 14,
                        lineHeight: '23px'
                    },
                    [`@media(min-width: 2559px)`]: {
                        maxWidth: '100%',
                        fontSize: 21,
                        lineHeight: '34.68px',
                        bottom:-40
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: 412,
                    maxWidth: '100%',
                    boxSizing: 'border-box',
                    borderRadius: 8,
                    [`@media(max-width: ${TABLET_WIDTH}px)`]: {
                        width: 319.03,
                        borderRadius: 6.19469
                    },
                    [`@media(max-width: ${MOBILE_WIDTH}px)`]: {
                        width: '100%'
                    },
                    [`@media(min-width: 2559px)`]: {
                        width: '100%'
                    }
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                input: {
                    height: '100%',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: '24px',
                    border: '1.5px solid transparent',
                    [`@media(max-width: ${TABLET_WIDTH}px)`]: {
                        fontSize: 13,
                        lineHeight: '19px',
                        borderWidth: 1.1615
                    },
                    [`@media(max-width: ${MOBILE_WIDTH}px)`]: {
                        fontSize: 15,
                        lineHeight: '18px',
                        paddingBottom: 6.5
                    },
                    [`@media(min-width: 2560px)`]: {
                        fontSize: 22,
                        lineHeight: '30px',
                        paddingBottom: 10
                    }
                },
                root: {
                    height: 52,
                    backgroundColor: '#3B3B43',
                    overflow: 'hidden',
                    color: '#FFFFFF',
                    borderRadius: 8,
                    position: 'relative',
                    boxSizing: 'border-box',
                    '&:hover': {
                        backgroundColor: '#3B3B43'
                    },
                    '&.Mui-focused': {
                        backgroundColor: '#3B3B43',
                        '& .MuiFilledInput-root': {
                            borderColor: '#FFFFFF'
                        }
                    },
                    '::before': {
                        display: 'none'
                    },
                    '::after': {
                        display: 'none'
                    },
                    '&.Mui-error': {
                        borderColor: '#E53737'
                    },
                    [`@media(max-width: ${TABLET_WIDTH}px)`]: {
                        height: 41.81,
                        borderRadius: 6.19469,
                        width: '100%',
                        maxWidth: '100%'
                    },
                    [`@media(min-width: 2559px)`]: {
                        height: 74,
                        borderRadius: 11,
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    color: '#999999',
                    fontSize: 12,
                    lineHeight: '14px',
                    fontWeight: 500,
                    [`@media(max-width: ${TABLET_WIDTH}px)`]: {
                        fontSize: 10,
                        lineHeight: '19px',
                        top: -2
                    },
                    [`@media(max-width: ${MOBILE_WIDTH}px)`]: {
                        fontSize: 15,
                        lineHeight: '18px'
                    }
                },
                root: {
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 16,
                    color: '#FFFFFF',
                    [`@media(max-width: ${TABLET_WIDTH}px)`]: {
                        fontSize: 13,
                        lineHeight: '19px',
                        top: -4
                    },
                    [`@media(max-width: ${MOBILE_WIDTH}px)`]: {
                        fontSize: 15,
                        lineHeight: '18px'
                    },
                    [`@media(min-width: 2559px)`]: {
                        fontSize: '22px',
                        lineHeight: '32px'
                    },
                    '&.Mui-focused': {
                        color: '#DFB729'
                    },
                    '&.Mui-error': {
                        color: '#E53737',
                        '&.Mui-focused': {
                            color: '#DFB729'
                        }
                    }
                }
            }
        }
    }
})

const RmdTextField = ({name, type, label, value, onChange, onBlur, error, className, showError = true}) => {
    return (
        <ThemeProvider theme={theme}>
            <TextField
                autoComplete={'off'}
                name={name}
                error={Boolean(error)}
                type={type}
                label={label}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                helperText={showError && error}
                variant={'filled'}
                InputProps={{
                    className
                }}
            />
        </ThemeProvider>
    )
}

export default RmdTextField