import {createTheme} from '@mui/material'
import {MOBILE_WIDTH} from '@root/config'

export const mobileAsideTheme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    width: '100%',
                    height: '100% !important',
                    backgroundColor: '#22222B',
                    borderTop: 'none !important',
                    padding: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    '& > svg': {
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        zIndex: -1,
                        [`@media(max-width: ${MOBILE_WIDTH}px)`]: {
                            bottom: 34,
                            right: -30
                        }
                    },
                    '& .mobile-aside__container': {
                        width: 706,
                        maxWidth: 'calc(100% - 20px)',
                        [`@media(max-width: ${MOBILE_WIDTH}px)`]: {
                            width: '100%',
                            height: '100%',
                            maxWidth: '335px',
                            padding: '20px 28px 0 28px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                        }
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    padding: 0,
                    '& .header__button.exit': {
                        position: 'fixed',
                        top: 30,
                        right: 49,
                        'svg': {
                            width: 14,
                            height: 14
                        },
                        [`@media(max-width: ${MOBILE_WIDTH}px)`]: {
                            top: 66.93,
                            right: 24.93,
                            'svg': {
                                width: 12.13,
                                height: 12.13
                            }
                        }
                    }
                }
            }
        }
    }
})