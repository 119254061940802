import React, { useCallback, useContext, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import HeaderToggleIcon from '@components/Header/Icons/HeaderToggleIcon'
import { ThemeProvider } from "@mui/material";
import Footer from "@components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authSlice } from "@store/reducers/authSlice";
import AsideExitIcon from "@components/Aside/icons/AsideExitIcon";
import MobileAsideBackgroundIcon from "@components/Aside/icons/MobileAsideBackgroundIcon";
import MobileAsidePointItem from "@components/Aside/components/MobileAsidePointItem";
import MobileAsideLogInIcon from "@components/Aside/icons/MobileAsideLogInIcon";
import { AsideContext, ModalContext } from "@context/context";
import { mobileAsideTheme } from "@themes/mobileAsideTheme";

const MobileAside = () => {
  // open close menu
  const [open, setOpen] = useState(false);
  const { authorized, user } = useSelector((state) => state.authReducer);
  const {
    signInProps: { setSignInActive },
  } = useContext(ModalContext);
  const { filteredPoints, shortName, fullName } = useContext(AsideContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout } = authSlice.actions;
  const logoutHandler = () => {
    navigate("/");
    dispatch(logout());
  };
  // close/open menu bar
  const toggleMenu = () => {
    setOpen((prev) => !prev);
  };
  const followLink = useCallback(
    (point) => (event) => {
      if (point?.disabled) {
        event.preventDefault();
        return;
      }
      point.active = !point.active;
      setTimeout(() => {
        toggleMenu();
        point.active = !point.active;
      }, 300);
    },
    []
  );
  const openSignInModal = () => {
    setOpen(false);
    setTimeout(() => {
      setSignInActive(true);
    }, 250);
  };
  return (
    <ThemeProvider theme={mobileAsideTheme}>
      <button
        onClick={toggleMenu}
        className={`header__button toggle${open ? " active" : ""}`}
      >
        <span />
        <span />
        <span />
      </button>
      <SwipeableDrawer
        anchor={"right"}
        open={open}
        onClose={toggleMenu}
        onOpen={toggleMenu}
      >
        <section className={`mobile-aside__container`}>
          <aside className={`aside`}>
            <div className={`aside__intro`}>
              {authorized && (
                <div className={`aside__header`}>
                  <div className={`aside__profile`}>{shortName}</div>
                  <div className={`aside__info`}>
                    <h2 className={`aside__info-title`}>{fullName}</h2>
                    <p className={`aside__info-subtitle`}>
                      {user["custom:clinic_name"]}
                    </p>
                  </div>
                </div>
              )}
              <div className={`aside__point-list`}>
                {filteredPoints.map((point) => (
                  <MobileAsidePointItem
                    closePanel={followLink(point)}
                    point={point}
                    key={point.id}
                    active={point.active}
                  />
                ))}
                {authorized ? (
                  <button onClick={logoutHandler} className={`aside__exit`}>
                    <div className={`aside-mobile__point-icon`}>
                      <AsideExitIcon />
                    </div>
                    <span>Log out</span>
                  </button>
                ) : (
                  <button
                    onClick={openSignInModal}
                    className={`aside__exit unauthorized`}
                  >
                    <div className={`aside-mobile__point-icon`}>
                      <MobileAsideLogInIcon />
                    </div>
                    <span>Log In</span>
                  </button>
                )}
              </div>
            </div>
          </aside>
          <Footer closeHandler={() => setOpen(false)} />
        </section>
        <MobileAsideBackgroundIcon />
      </SwipeableDrawer>
    </ThemeProvider>
  );
};

export default MobileAside;
