import React from 'react'

const MobileAsideLogInIcon = () => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.73666 0.378906C7.13285 0.378906 5.01446 2.49242 5.01446 5.09024V6.08009C5.01446 6.51979 5.37214 6.87664 5.81285 6.87664C6.25356 6.87664 6.61124 6.51979 6.61124 6.08009V5.09024C6.61124 3.36969 8.0132 1.97201 9.73666 1.97201H14.9262C16.6475 1.97201 18.0484 3.36969 18.0484 5.09024V16.91C18.0484 18.6295 16.6475 20.0272 14.9262 20.0272H9.72495C8.00895 20.0272 6.61124 18.6337 6.61124 16.9217V15.9202C6.61124 15.4805 6.25356 15.1236 5.81285 15.1236C5.37214 15.1236 5.01446 15.4805 5.01446 15.9202V16.9217C5.01446 19.5131 7.12859 21.6203 9.72495 21.6203H14.9262C17.5278 21.6203 19.6452 19.5078 19.6452 16.91V5.09024C19.6452 2.49242 17.5278 0.378906 14.9262 0.378906H9.73666Z"
                  fill="#DFB729"/>
            <path
                d="M9.93137 14.6568C10.0864 14.814 10.2914 14.8915 10.4954 14.8915C10.6982 14.8915 10.9021 14.814 11.0572 14.6589L14.1681 11.5629C14.3189 11.4142 14.4029 11.2103 14.4029 10.9989C14.4029 10.7875 14.3189 10.5836 14.1681 10.4349L11.0572 7.33781C10.746 7.02767 10.2426 7.02767 9.93137 7.33993C9.62018 7.65219 9.6223 8.15563 9.9335 8.46577L11.6774 10.2031H0.816052C0.376339 10.2031 0.0194702 10.56 0.0194702 10.9997C0.0194702 11.4394 0.376339 11.7963 0.816052 11.7963H11.6755L9.9335 13.531C9.6223 13.8411 9.62018 14.3445 9.93137 14.6568Z"
                fill="#DFB729"/>
        </svg>
    )
}

export default MobileAsideLogInIcon