import React from 'react'
import {createSlice} from '@reduxjs/toolkit'
import AnonymizationLipsIcon from '@pages/Anonymization/components/Icons/List View/AnonymizationLipsIcon'
import AnonymizationAboveTheEyesIcon
    from '@pages/Anonymization/components/Icons/List View/AnonymizationAboveTheEyesIcon'
import AnonymizationUnderTheEyesIcon
    from '@pages/Anonymization/components/Icons/List View/AnonymizationUnderTheEyesIcon'
import AnonymizationBetweenTheEyesIcon
    from '@pages/Anonymization/components/Icons/List View/AnonymizationBetweenTheEyesIcon'
import * as _ from 'lodash'

export const defaultAnonymizationFilters = [
    {
        id: 1,
        title: 'mouth & lips',
        param: 'lips',
        getIcon: () => <AnonymizationLipsIcon/>,
        disabled: false,
        group: 'lips'
    },
    {
        id: 2,
        title: 'above the eyes',
        param: 'above',
        getIcon: () => <AnonymizationAboveTheEyesIcon/>,
        disabled: false,
        group: 'eyes'
    },
    {
        id: 3,
        title: 'under the eyes',
        param: 'under',
        getIcon: () => <AnonymizationUnderTheEyesIcon/>,
        disabled: false,
        group: 'eyes'
    },
    {
        id: 4,
        title: 'between the eyes',
        param: 'between',
        getIcon: () => <AnonymizationBetweenTheEyesIcon/>,
        disabled: false,
        group: 'between'
    },
        {
        id: 5,
        title: 'forehead',
        param: 'forehead',
        getIcon: () => <AnonymizationBetweenTheEyesIcon/>,
        disabled: false,
        group: 'forehead'
    },
    {
        id: 6,
        title: 'chin',
        param: 'chin',
        getIcon: () => <AnonymizationBetweenTheEyesIcon/>,
        disabled: false,
        group: 'chin'
    },
    {
        id: 7,
        title: 'nose',
        param: 'nose',
        getIcon: () => <AnonymizationBetweenTheEyesIcon/>,
        disabled: false,
        group: 'nose'
    },
    {
        id: 8,
        title: 'temples',
        param: 'temples',
        getIcon: () => <AnonymizationBetweenTheEyesIcon/>,
        disabled: false,
        group: 'temples'
    },
    {
        id: 9,
        title: 'perioral',
        param: 'perioral',
        getIcon: () => <AnonymizationBetweenTheEyesIcon/>,
        disabled: false,
        group: 'perioral'
    },
    {
        id: 10,
        title: 'midface',
        param: 'midface',
        getIcon: () => <AnonymizationBetweenTheEyesIcon/>,
        disabled: false,
        group: 'midface'
    },

]

export const defaultAnonymizationComments = [
    {
        id: 1, param: 'above', options: [
            {id: 1, title: 'Mini Brow Lift'},
            {id: 2, title: 'Ptosis'},
            {id: 3, title: 'Upper Eyelid'}
        ]
    },
    {
        id: 2, param: 'under', options: [
            {id: 4, title: 'Tear trough'},
            {id: 5, title: 'Lower Eyelid'}
        ]
    },
    // {
    //     id: 3, param: 'between', options: [
    //         {id: 6, title: 'Frown lines'},
    //         {id: 7, title: 'Lower Forehead lines'},
    //         {id: 8, title: 'Bunny lines'}
    //     ]
    // },
    {
        id: 4, param: 'lips', options: [
            {id: 9, title: 'Upper Lip'},
            {id: 10, title: 'Lower Lip'},
            {id: 11, title: 'Cupid’s bow'}
        ]
    }
]

const initialState = {
    anonymizationStep: 0,
    anonymizationTreatment: [],
    anonymizationTreatmentDisabled:[],
    anonymizationFilters: _.cloneDeep(defaultAnonymizationFilters),
    anonymizationComments: _.cloneDeep(defaultAnonymizationComments),
    anonymizationSelectedComments: [],
    anonymizationCandidates: [],
    anonymizationCandidate: {},
    anonymizationCurrentPair: {},
    anonymizationCurrentPairIndex: 0,
    anonymizationSelectedGroup: '',
    anonymizationSelectedPair: {},
    anonymizationComment: '',
    anonymizationOtherComment: '',
    anonymizationResults: {},
    anonymizationSelectedResultsPair: {},
    anonymizationSelectedResultsPairIndex: 0,
    anonymizationShareResults: {},
    anonymizationErrors: {},
    anonymizationError: null,
    anonymizationVideo:{},
    anonymizationVideoError:null,

}

export const anonymizationSlice = createSlice({
    name: 'anonymization',
    initialState,
    reducers: {
        clearAll(state) {
            state.anonymizationTreatment = []
            state.anonymizationTreatmentDisabled = []
            state.anonymizationFilters = _.cloneDeep(defaultAnonymizationFilters)
            state.anonymizationComments = _.cloneDeep(defaultAnonymizationComments)
            state.anonymizationSelectedComments = []
            state.anonymizationCandidates = []
            state.anonymizationCandidate = {}
            state.anonymizationCurrentPair = {}
            state.anonymizationCurrentPairIndex = 0
            state.anonymizationSelectedGroup = ''
            state.anonymizationSelectedPair = {}
            state.anonymizationComment = ''
            state.anonymizationOtherComment = ''
            state.anonymizationResults = {}
            state.anonymizationSelectedResultsPair = {}
            state.anonymizationSelectedResultsPairIndex = 0
            state.anonymizationShareResults = {}
            state.anonymizationErrors = {}
            state.anonymizationError = null
            state.anonymizationVideo = {}
            state.anonymizationVideoError = null
        },
        clearAnonymizationResults(state) {
            state.anonymizationResults = {}
            state.anonymizationSelectedResultsPair = {}
            state.anonymizationSelectedResultsPairIndex = 0
        },
        setAnonymizationStep(state, action) {
            state.anonymizationStep = action.payload
        },
        increaseAnonymizationStep(state) {
            state.anonymizationStep += 1
        },
        decreaseAnonymizationStep(state) {
            state.anonymizationStep -= 1
        },
        setAnonymizationTreatment(state, action) {
            state.anonymizationTreatment = action.payload
        },
        setAnonymizationTreatmentDisabled(state, action) {
            state.anonymizationTreatmentDisabled = action.payload
        },
        setAnonymizationFilters(state, action) {
            state.anonymizationFilters = action.payload
        },
        setDefaultAnonymizationFilters(state) {
            state.anonymizationFilters = _.cloneDeep(defaultAnonymizationFilters)
        },
        setAnonymizationComments(state, action) {
            state.anonymizationComments = action.payload
        },
        setDefaultAnonymizationComments(state) {
            state.anonymizationComments = _.cloneDeep(defaultAnonymizationComments)
        },
        setAnonymizationSelectedComments(state, action) {
            state.anonymizationSelectedComments = action.payload
        },
        setAnonymizationCandidates(state, action) {
            state.anonymizationCandidates = action.payload
        },
        setAnonymizationCandidate(state, action) {
            state.anonymizationCandidate = action.payload
        },
        setAnonymizationCurrentPair(state, action) {
            state.anonymizationCurrentPair = action.payload
        },
        setAnonymizationCurrentPairIndex(state, action) {
            state.anonymizationCurrentPairIndex = action.payload
        },
        setAnonymizationSelectedGroup(state, action) {
            state.anonymizationSelectedGroup = action.payload
        },
        setAnonymizationComment(state, action) {
            state.anonymizationComment = action.payload
        },
        setAnonymizationOtherComment(state, action) {
            state.anonymizationOtherComment = action.payload
        },
        setAnonymizationImage(state, action) {
            const {name, ...other} = action.payload
            state.anonymizationSelectedPair[name] = other
        },
        setAnonymizationResults(state, action) {
            state.anonymizationResults = action.payload
        },
        setAnonymizationSelectedResultsPair(state, action) {
            state.anonymizationSelectedResultsPair = action.payload
        },
        setAnonymizationResultsPairIndex(state, action) {
            state.anonymizationSelectedResultsPairIndex = action.payload
        },
        setAnonymizationShareResults(state, action) {
            state.anonymizationShareResults = action.payload
        },
        setAnonymizationError(state, action) {
            state.anonymizationError = action.payload
        },
        setAnonymizationErrors(state, action) {
            state.anonymizationErrors = action.payload
        },
        setAnonymizationVideo(state, action) {
            state.anonymizationVideo = action.payload
        },
        setAnonymizationVideoError(state, action) {
            state.anonymizationVideoError = action.payload
        },
    }
})

export const actions = anonymizationSlice.actions

export default anonymizationSlice.reducer