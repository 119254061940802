import React, {useContext, useRef, useEffect} from 'react'
import {Form, Formik} from 'formik'
import RmdTextField from '@components/Custom/RMDTextField/RMDTextField'
import {useDispatch, useSelector} from 'react-redux'
import {forgotPassword, getUserData, signIn} from '@store/actions/authActions'
import {SignInSchema} from '@validation/signInSchema'
import {ModalContext} from '@context/context'
import {modalsSlice} from '@store/reducers/modalsSlice'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'

const SignInInputForm = () => {
    const {signInProps: {signInActive, setSignInActive}} = useContext(ModalContext)
    const {error} = useSelector(state => state.authReducer)
    const {increaseSignInStep} = modalsSlice.actions
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let formikRef = useRef(null)
    const signInHandler = (values, {setSubmitting, setErrors}) => {
        dispatch(signIn(values))
            .then((res) => {
                if (res?.error) {
                    setTimeout(() => {
                        setSubmitting(false)
                        setErrors({
                            email: ''
                        })
                    }, 0)
                    return
                }
                setTimeout(() => {
                    setSignInActive(false)
                    localStorage.removeItem('signUpStep')
                    localStorage.removeItem('signInStep')
                }, 0)
                dispatch(getUserData(values?.email.replace('@', '#')))
                navigate('/anonymization')
            })
            .catch((err) => {
                console.log(err)
                setSubmitting(false)
            })
    }
    const forgotPasswordHandler = (values) => () => {
        const validEmail = Yup.string()
            .email('Invalid email address')
            .required('Required')
        validEmail.isValid(values?.email)
            .then(res => {
                if (!res) {
                    return
                }
                const newValues = {...values, username: values?.email?.replace('@', '#')}
                dispatch(forgotPassword(newValues?.username))
                    .then(() => {
                        dispatch(increaseSignInStep())
                        setTimeout(() => {
                            navigate('/', {
                                state: {
                                    values: newValues
                                }
                            })
                        }, 0)
                    })
            })
    }
    useEffect(() => {
        if (signInActive) {
            return
        }
        formikRef.resetForm({})
    }, [signInActive])
    return (
        <Formik
            initialValues={{email: '', password: ''}}
            validationSchema={signInActive && SignInSchema}
            innerRef={(ref) => {
                formikRef = ref
            }}
            onSubmit={signInHandler}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    setErrors,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    handleBlur
                } = props
                return (
                    <Form noValidate={true} onSubmit={handleSubmit}>
                        <div className={`sign-in__label input`}>
                            <RmdTextField
                                label={'Email address'}
                                type={'email'}
                                name={'email'}
                                value={values?.email || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched?.email && errors?.email}
                                className={`sign-in__field${touched?.email && errors?.email ? ' error' : ''}`}
                            />
                        </div>
                        <div className={`sign-in__label input`}>
                            <RmdTextField
                                label={'Password'}
                                type={'password'}
                                name={'password'}
                                value={values?.password || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={error || (touched?.password && errors?.password)}
                                className={`sign-in__field${error || (touched?.password && errors?.password) ? ' error' : ''}`}
                            />
                        </div>
                        <div className={`sign-in__forgot`}>
                            <button
                                onClick={forgotPasswordHandler(values, setErrors)}
                                type={'button'}
                            >
                                Forgot password?
                            </button>
                        </div>
                        <button
                            type={'submit'}
                            disabled={isSubmitting}
                            className={`sign-in__button login`}
                        >
                            Log In
                        </button>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SignInInputForm