import * as Yup from 'yup'

export const SignInSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
    password: Yup.string().required('Required')
})

export const SignUpSchema = Yup.object().shape({
    clinic_name: Yup.string()
        .required('Required'),
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    agree: Yup.boolean().oneOf([true])
})