import React, {useEffect, useMemo, useState} from 'react'
import SignFeedbackIcon from '@components/Icons/SignFeedbackIcon'
import ReactCodeInput from 'react-code-input'
import {useDispatch} from 'react-redux'
import {confirmRegistration, resendCode} from '@store/actions/authActions'
import {useLocation, useNavigate} from 'react-router-dom'
import {modalsSlice} from '@store/reducers/modalsSlice'
import icon from '@img/RealizeMD white logo.svg'

const mainProps = {}

const SignUpVerificationStep = () => {
    const [code, setCode] = useState('')
    const [valid, setValid] = useState(false)
    const [error, setError] = useState(null)
    const {increaseSignUpStep} = modalsSlice.actions
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const props = useMemo(() => ({
        inputStyle: {
            ...mainProps,
            border: `1.5px solid ${valid && !error ? '#DFB729' : 'transparent'}`,
        },
        inputStyleInvalid: {
            ...mainProps,
            border: `1.5px solid ${error && !valid ? '#E53737' : 'transparent'}`
        }
    }), [valid, error])
    const changeCode = (value) => {
        setError(false)
        if (!Number(value)) {
            setValid(false)
            setError('Invalid verification code')
        }
        setCode(() => {
            if (value?.length === 6) {
                checkValidCode(value)
            }
            return value
        })
    }
    const checkValidCode = (code) => {
        let signInValues = localStorage?.signInForm ? JSON.parse(localStorage?.signInForm) : location.state?.values
        const submitForm = {
            username: signInValues?.username,
            code
        }
        dispatch(confirmRegistration(submitForm))
            .then((res) => {
                if (!_.isEmpty(res?.error)) {
                    setError(res?.error?.message)
                    return
                }
                dispatch(increaseSignUpStep())
                signInValues = {...signInValues, code}
                localStorage.setItem('signInForm', JSON.stringify(signInValues))
                setTimeout(() => {
                    navigate(location.pathname, {
                        state: {
                            values: signInValues
                        }
                    })
                }, 0)
            })
            .catch(err => {
                console.error(err)
            })
    }
    const resendCodeHandler = () => {
        if (_.isEmpty(location.state?.values)) {
            return
        }
        const {values: {username}} = location.state
        dispatch(resendCode(username))
    }
    useEffect(() => {
        if (!code.length) {
            setError(null)
        }
        setValid(code.length === 6 && !error)
    }, [code, error])
    useEffect(() => {
        if (valid) {
            setError(null)
        }
    }, [valid])
    return (
        <div className={`sign-up__intro`}>
            <img src={icon} alt={''} className={`sign-up__logo`}/>
            <h2 className={`sign-in__title verification`}>Check your inbox <SignFeedbackIcon/></h2>
            <p className={`sign-in__subtitle verification first`}>
                A verification code has been sent to your email address.
            </p>
            <p className={`sign-in__subtitle verification`}>Enter the code to log in:</p>
            <div className={`sign-in__verification`}>
                <ReactCodeInput
                    value={code}
                    onChange={changeCode}
                    name={'verification'}
                    inputMode={'numeric'}
                    fields={6}
                    autoFocus={true}
                    isValid={valid}
                    className={`sign-in__code`}
                    {...props}
                />
            </div>
            <p className={`sign-in__error verification${error ? ' active' : ''}`}>{error}</p>
            <p className={`sign-in__description verification`}>
                Didn't receive an email?<br/>
                check your spam or junk mail folder.
            </p>
            <button
                onClick={resendCodeHandler}
                className={`sign-in__button send`}
            >
                Send me the email again
            </button>
        </div>
    )
}

export default SignUpVerificationStep