import React from 'react'
import {NavLink} from 'react-router-dom'
import Navbar from '@components/Navbar/Navbar'
import {useSelector} from 'react-redux'
import icon from '@img/logo__icon.svg'
import MobileAside from '@components/Aside/MobileAside'

const Header = () => {
    const {authorized} = useSelector(state => state.authReducer)
    const {currentSize} = useSelector(state => state.mainReducer)
    return (
        <header className={`header`}>
            <div className={`header__intro`}>
                <div className={`header__content`}>
                    <NavLink to={'/'}>
                        <img src={icon} alt="RealizedMD logo" className={`header__logo`}/>
                    </NavLink>
                    {(currentSize > 1025 ? (!authorized && <Navbar/>) : <MobileAside/>)}
                </div>
            </div>
        </header>
    )
}

export default Header