import React from 'react'

const HomeIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" labelledby="homeButton homeButtonDesc" role="img">
            <title id="homeButton">Button redirects to Home page</title>
            <desc id="homeButtonDesc">An illustrated button redirects to Home page</desc>
            <path
                fillRule="evenodd" clipRule="evenodd"
                d="M12.5743 14.62C13.8664 14.62 14.9181 15.6154 14.9181 16.8389V19.9857C14.9181 20.2486 15.1392 20.4594 15.4225 20.4655H17.468C19.0799 20.4655 20.3902 19.2317 20.3902 17.7157V8.79095C20.3827 8.26921 20.122 7.77817 19.6744 7.45183L12.599 2.07287C11.6493 1.35574 10.3207 1.35574 9.36771 2.07491L2.34059 7.44979C1.8759 7.78635 1.61512 8.2774 1.60976 8.80834V17.7157C1.60976 19.2317 2.9201 20.4655 4.532 20.4655H6.59678C6.88761 20.4655 7.12371 20.2455 7.12371 19.9755C7.12371 19.9161 7.13122 19.8568 7.1441 19.8005V16.8389C7.1441 15.6226 8.18937 14.6282 9.47181 14.62H12.5743ZM17.468 22H15.4032C14.2206 21.9734 13.3084 21.0885 13.3084 19.9857V16.8389C13.3084 16.4614 12.9789 16.1545 12.5743 16.1545H9.47717C9.08117 16.1566 8.75385 16.4645 8.75385 16.8389V19.9755C8.75385 20.0522 8.74312 20.1259 8.72059 20.1954C8.60468 21.2082 7.69678 22 6.59678 22H4.532C2.03258 22 0 20.0778 0 17.7157V8.80118C0.0107317 7.78431 0.502244 6.85337 1.35112 6.24059L8.36429 0.874926C9.90859 -0.291301 12.0603 -0.291301 13.6014 0.87288L20.665 6.24366C21.4945 6.84621 21.986 7.7751 22 8.77969V17.7157C22 20.0778 19.9674 22 17.468 22Z"
                fill="#858798"
            />
        </svg>
    )
}

export default HomeIcon