import React, {useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import SignUpInputStep from '@components/Modals/SignUp/Steps/SignUpInputStep'
import SignUpCreateStep from '@components/Modals/SignUp/Steps/SignUpCreateStep'
import SignUpVerificationStep from '@components/Modals/SignUp/Steps/SignUpVerificationStep'

const useSignUp = () => {
    const {signUpStep} = useSelector(state => state.modalsReducer)
    const steps = useMemo(() => {
        return [<SignUpInputStep/>, <SignUpVerificationStep/>, <SignUpCreateStep/>]
    }, [])
    const currentStep = useCallback(() => steps[signUpStep], [steps, signUpStep])
    return {
        currentStep
    }
}

export default useSignUp