import React, {useContext} from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import {ModalContext} from '@context/context'
import {useSelector} from 'react-redux'

const HomePage = () => {
    const {signUpProps: {setSignUpActive}} = useContext(ModalContext)
    const {authorized} = useSelector(state => state.authReducer)
    const navigate = useNavigate()

    // open anonimization page or open sign up modal window 
    const startNow = () => {
        authorized ? navigate('/anonymization') : setSignUpActive(true)
    }
    return (
        <section className={`home`}>
            <div className={`home__intro`}>
                <h2 className={`home__title`}>Your experience counts, <span>show it!</span></h2>
                <p className={`home__subtitle`}>
                    Let us liberate your work results, while maintaining privacy.<br/>
                    Check our single image Demo, or start using your actual before & after images.
                </p>
                <div className={`home__footer`}>
                    <button
                        onClick={startNow}
                        className={`home__link start`}
                    >
                        start Now
                    </button>
                    <NavLink to={'/demo'} className={`home__link demo-link`}>single image demo</NavLink>
                </div>
            </div>
        </section>
    )
}

export default HomePage