import React from 'react'

const AnonymizationIcon = () => {
    return (
        <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg" labelledby="AnonymizationIconButton AnonymizationIconButtonDesc" role="img">
            <title id="AnonymizationIconButton">Button redirects to Anonymization page</title>
            <desc id="AnonymizationIconButtonDesc">An illustrated button redirects to Anonymization page</desc>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.1871 0.841456C20.0123 0.292968 20.9827 1.9045e-05 21.9757 0C23.3069 0.00165673 24.5824 0.528486 25.5228 1.46396C26.4631 2.39936 26.9914 3.66687 26.9931 4.98832C26.993 5.97409 26.6992 6.93799 26.1483 7.75815C25.5974 8.57841 24.814 9.2182 23.8968 9.59612C22.9797 9.97406 21.9703 10.073 20.9964 9.88028C20.0225 9.68758 19.1284 9.21199 18.4269 8.51414C17.7255 7.81632 17.2481 6.92764 17.0548 5.96065C16.8614 4.99369 16.9606 3.99136 17.34 3.08029C17.7193 2.16919 18.362 1.38994 19.1871 0.841456ZM23.2266 1.9782C22.6293 1.73205 21.9721 1.66769 21.3382 1.79314C20.7042 1.91859 20.1215 2.22832 19.6638 2.68363C19.206 3.13899 18.894 3.71953 18.7675 4.35202C18.641 4.98454 18.706 5.64013 18.954 6.23574C19.2019 6.83132 19.6217 7.33985 20.1596 7.6974C20.6974 8.05493 21.3297 8.24558 21.976 8.24558C22.8422 8.24462 23.6733 7.90188 24.2868 7.2916C24.9004 6.6812 25.2461 5.85275 25.2471 4.98812C25.2471 4.34314 25.0548 3.71292 24.695 3.17716C24.3351 2.64143 23.824 2.22436 23.2266 1.9782Z"
                  fill="#858798"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18.4232 15.6866C17.4832 16.6211 16.8062 18.0666 16.8062 20.1022C16.8062 20.598 16.4019 21 15.9031 21C15.4043 21 15 20.598 15 20.1022C15 17.6484 15.8282 15.727 17.146 14.4168C18.4553 13.1151 20.1953 12.4703 21.9239 12.4703C22.6935 12.4703 23.8232 12.5672 24.5576 13.086C25.2919 13.6049 24.5079 14.8678 23.7392 14.584C23.1236 14.3568 22.5694 14.2661 21.9239 14.2661C20.6421 14.2661 19.3716 14.7436 18.4232 15.6866Z"
                  fill="#858798"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4.13525 0.841456C4.96041 0.292968 5.93084 1.9045e-05 6.92382 0C8.255 0.00165673 9.53057 0.528486 10.4709 1.46396C11.4112 2.39936 11.9396 3.66687 11.9412 4.98832C11.9412 5.97409 11.6473 6.93799 11.0965 7.75815C10.5455 8.57841 9.76212 9.2182 8.84497 9.59612C7.92779 9.97406 6.91838 10.073 5.94451 9.88028C4.97065 9.68758 4.07653 9.21199 3.37504 8.51414C2.67359 7.81632 2.19625 6.92764 2.0029 5.96065C1.80956 4.99369 1.90876 3.99136 2.28811 3.08029C2.66747 2.16919 3.31011 1.38994 4.13525 0.841456ZM8.17476 1.9782C7.5774 1.73205 6.92026 1.66769 6.2863 1.79314C5.65233 1.91859 5.06957 2.22832 4.61188 2.68363C4.15416 3.13899 3.84208 3.71953 3.71561 4.35202C3.58914 4.98454 3.65408 5.64013 3.90208 6.23574C4.15006 6.83132 4.56978 7.33985 5.10769 7.6974C5.64556 8.05493 6.27777 8.24558 6.92408 8.24558C7.79034 8.24462 8.62146 7.90188 9.23493 7.2916C9.84851 6.6812 10.1942 5.85275 10.1952 4.98812C10.1952 4.34314 10.0029 3.71292 9.64307 3.17716C9.28325 2.64143 8.77215 2.22436 8.17476 1.9782Z"
                  fill="#858798"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3.4232 15.6866C2.48324 16.6211 1.80623 18.0666 1.80623 20.1022C1.80623 20.598 1.40189 21 0.903113 21C0.404337 21 0 20.598 0 20.1022C0 17.6484 0.828176 15.727 2.146 14.4168C3.45533 13.1151 5.19528 12.4703 6.92387 12.4703C8.65245 12.4703 10.3924 13.1151 11.7017 14.4168C13.0196 15.727 13.8477 17.6484 13.8477 20.1022C13.8477 20.598 13.4434 21 12.9446 21C12.4458 21 12.0415 20.598 12.0415 20.1022C12.0415 18.0666 11.3645 16.6211 10.4245 15.6866C9.47608 14.7436 8.20566 14.2661 6.92387 14.2661C5.64207 14.2661 4.37165 14.7436 3.4232 15.6866Z"
                  fill="#858798"/>
            <path d="M28 15L28 21" stroke="#858798" strokeWidth="1.7" strokeLinecap="round"/>
            <path d="M31 18H25" stroke="#858798" strokeWidth="1.7" strokeLinecap="round"/>
        </svg>
    )
}

export default AnonymizationIcon