import {createSlice} from '@reduxjs/toolkit'
import {getPostCards} from '@store/actions/portfolioActions'

const initialState = {
    postCards: [],
    count: 0,
    nextApi: null,
    prevApi: null,
    error: null
}

export const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState,
    reducers: {
        clearAll(state) {
            state.postCards = []
            state.count = 0
            state.nextApi = null
            state.prevApi = null
            state.error = null
        },
        setPostCards(state, action) {
            state.postCards = action.payload
        }
    },
    extraReducers: {
        [getPostCards.fulfilled]: (state, action) => {
            const {next, previous, results, count} = action.payload
            state.postCards = results
            state.count = count
            state.nextApi = next
            state.prevApi = previous
            state.error = null
        }
    }
})

export default portfolioSlice.reducer