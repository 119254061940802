import thunkMiddleware from 'redux-thunk'
import {devToolsEnhancer} from 'redux-devtools-extension'
import {rootReducer} from '@store/reducers/rootReducer'
import {configureStore} from '@reduxjs/toolkit'

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(thunkMiddleware),
    devTools: devToolsEnhancer
})