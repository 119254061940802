import React from 'react'

const PrivateBetaIcon = () => {
    return (
        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" labelledby="PrivateBetaIconButton PrivateBetaIconButtonDesc" role="img">
            <title id="PrivateBetaIconButton">Button redirects to Private page</title>
            <desc id="PrivateBetaIconButtonDesc">An illustrated button redirects to Private page</desc>
            <path d="M3.4694 0.5H0.5V3.46002H3.4694V0.5Z" fill="#858798"/>
            <path d="M6.55609 4.10516H4.20673L4.20708 6.45178H6.55645L6.55609 4.10516Z" fill="#858798"/>
            <path d="M4.22278 8.29034H5.76739V25.7494H4.22278V8.29034Z" fill="#858798"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.3689 4.12466L8.39862 4.1236V5.665L16.9417 5.66502C18.2266 5.66502 19.5092 6.11263 20.6166 7.20524C21.1701 7.75144 21.5507 8.26182 21.801 8.82004C22.051 9.37775 22.1774 9.99787 22.2027 10.7722C22.2296 11.5986 22.1263 12.2728 21.8793 12.8822C21.6321 13.4923 21.2345 14.053 20.6508 14.6437C19.7873 15.5177 18.7284 15.935 17.858 16.1421L17.6025 16.2029L19.6978 23.4136C19.6675 23.4595 19.63 23.5185 19.5915 23.5844C19.5085 23.7265 19.3944 23.9438 19.371 24.1352C19.3311 24.4611 19.4041 24.7907 19.5776 25.0695C19.7512 25.3483 20.0149 25.5595 20.325 25.6683C20.6352 25.7772 20.9732 25.7772 21.2834 25.6685C21.5935 25.5597 21.8573 25.3486 22.031 25.0699C22.2047 24.7912 22.2777 24.4616 22.238 24.1358C22.1983 23.8099 22.0481 23.5074 21.8126 23.2784C21.6202 23.0913 21.3802 22.9623 21.1203 22.9042L19.5644 17.3207C20.2836 16.9916 21.0384 16.458 21.7467 15.6988C22.4435 14.952 22.9596 14.2384 23.2934 13.4454C23.6281 12.6505 23.7736 11.7905 23.7499 10.7583C23.7195 9.43832 23.486 8.62918 22.8155 7.49263C22.1279 6.32718 21.5441 5.66395 20.3532 4.98289C19.1683 4.30526 18.293 4.12065 16.9439 4.1237C16.3741 4.12499 14.3005 4.12499 12.3689 4.12466ZM20.7454 24.003C20.8058 23.991 20.8685 23.9971 20.9253 24.0206C20.9822 24.0441 21.0306 24.0838 21.0647 24.1346C21.0987 24.1853 21.1168 24.2449 21.1168 24.3058C21.1168 24.3875 21.0843 24.466 21.0261 24.524C20.9679 24.582 20.8888 24.6147 20.8062 24.6147C20.7446 24.6147 20.6844 24.5965 20.6333 24.5624C20.5822 24.5284 20.5425 24.4801 20.5191 24.4238C20.4957 24.3674 20.4896 24.3055 20.5015 24.2457C20.5134 24.186 20.5429 24.131 20.5863 24.0877C20.6297 24.0445 20.685 24.0149 20.7454 24.003Z"
                  fill="#858798"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.5708 8.81955C17.1955 8.8101 17.7434 9.04295 18.2303 9.53189C18.481 9.78358 18.6259 10.0065 18.7459 10.2998C18.8662 10.5938 18.9191 10.8648 18.9249 11.2236C18.9357 11.8991 18.7519 12.5725 18.3608 13.0832C17.9646 13.6008 17.3637 13.9388 16.5818 13.9388L14.4169 13.9375L16.212 21.212C16.4443 21.2752 16.6552 21.4056 16.8222 21.588C17.0298 21.8147 17.1579 22.1086 17.1896 22.4202C17.2213 22.7317 17.1553 23.0472 17 23.3154C16.8445 23.5838 16.6071 23.7911 16.3231 23.898C16.0385 24.0051 15.7281 24.003 15.4448 23.8924C15.162 23.7819 14.9271 23.5716 14.7746 23.3013C14.6223 23.0314 14.5597 22.7151 14.5947 22.404C14.6244 22.1408 14.7228 21.8911 14.8796 21.6848L12.5107 12.4296L16.5927 12.4283C16.8357 12.4389 17.0771 12.3467 17.2654 12.1633C17.3289 12.099 17.3972 11.978 17.4459 11.8064C17.4937 11.6379 17.5169 11.44 17.5055 11.2419C17.4954 11.0647 17.4908 10.9957 17.4393 10.8733C17.3857 10.7461 17.3447 10.6883 17.2412 10.5874C17.0395 10.3906 16.8257 10.3204 16.5815 10.329L16.5728 10.3293L10.3239 10.3303V25.7497H8.89832V8.8208L16.5708 8.81955ZM15.8868 22.2805C15.8435 22.2805 15.799 22.2944 15.759 22.3237C15.7187 22.3531 15.6841 22.3974 15.6629 22.4533C15.6417 22.5093 15.6358 22.572 15.6469 22.6327C15.6579 22.6933 15.6847 22.7463 15.7209 22.7858C15.7569 22.8251 15.8 22.849 15.8433 22.8585C15.8863 22.8678 15.9312 22.8633 15.9733 22.8442C16.0158 22.825 16.0558 22.7907 16.0855 22.7421C16.1152 22.6935 16.1322 22.6341 16.1322 22.5718C16.1322 22.4878 16.1015 22.4111 16.0527 22.3579C16.0046 22.3053 15.9444 22.2805 15.8868 22.2805Z"
                  fill="#858798"/>
        </svg>
    )
}

export default PrivateBetaIcon